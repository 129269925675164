:root {
  --white: #fff;
  --white-primary: #ffeff5;
  --black: #141516;
  --black-primary: #272123;
  --white-transp-1: #ffffffb3;
  --white-transp-2: #ffffff4d;
  --gray: #8b8b8b;
  --primary: #bb2464;
  --secondary: #3d4963;
  --bg-color: var(--white);
  --fg-color: var(--black);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --margin-x-sm: 24px;
  --margin-y-sm: 24px;
  --margin-x-md: 32px;
  --margin-y-md: 32px;
  --margin-x: 24px;
  --margin-y: 24px;
  --section-margin-y: 4rem;
  --bs-font-sans-serif: "Inter", -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --transition-curve-1: cubic-bezier(0, 0, 0, 1);
  --transition-curve-2: cubic-bezier(.25, .75, .2, 1);
}

@media screen and (width >= 768px) {
  :root {
    --margin-x: 32px;
    --margin-y: 32px;
  }
}

@media screen and (width >= 992px) {
  :root {
    --margin-x: 64px;
    --margin-y: 64px;
  }
}

@media screen and (width >= 768px) {
  :root {
    --section-margin-y: 5rem;
  }
}

@media screen and (width >= 992px) {
  :root {
    --section-margin-y: 8rem;
  }
}

html {
  font-size: 16px;
}

html.smooth-scroll {
  scroll-behavior: smooth;
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
}

body {
  color: #141516;
  background: #fff;
}

a, a:hover {
  color: currentColor;
}

.bg-gradient-primary {
  background: linear-gradient(to left, #bb2464, #250234);
}

.bg-primary {
  background-color: #bb2464 !important;
}

.bg-secondary {
  background-color: #3d4963 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #141516 !important;
}

.filter-black {
  filter: grayscale();
}

.bg-black-demo {
  background: #2d2d2d;
}

.strong {
  font-weight: bold;
}

.text-sm {
  font-size: .875rem;
}

.text-p-xl p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7;
}

@media screen and (width >= 768px) {
  .text-p-xl p {
    font-size: 1.5rem;
    line-height: 1.8;
  }
}

@media screen and (width >= 992px) {
  .text-p-xl p {
    font-size: 3rem;
    line-height: 1.4;
  }
}

.text-p-xl p strong, .text-p-xl p .strong {
  font-weight: 600;
}

.text-p-lg p {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media screen and (width >= 768px) {
  .text-p-lg p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

@media screen and (width >= 992px) {
  .text-p-lg p {
    font-size: 2rem;
    line-height: 1.5;
  }
}

.text-p-md {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media screen and (width >= 992px) {
  .text-p-md p {
    font-size: 2rem;
    line-height: 1.5;
  }
}

.text-p-mm p {
  font-size: 1.5rem;
  line-height: 1.5;
}

.section-border-top.anim-ready.anim-visible {
  background-color: #000;
  top: -1px;
}

@media screen and (width <= 991.98px) {
  .section-border-top.anim-ready.anim-visible {
    margin-left: 12px;
    margin-right: 12px;
    height: 12px !important;
  }
}

@media screen and (width >= 992px) {
  .section-border-top.anim-ready.anim-visible {
    height: 24px !important;
  }
}

@media screen and (width <= 992px) {
  footer.hero-footer.hide-on-scroll {
    display: none;
  }
}

@media screen and (width <= 991.98px) {
  .hero-a .hero-content {
    margin-top: -30rem;
  }
}

@media screen and (width <= 992px) {
  .item-hover-icon, p.number.title-font.text-stroke {
    display: none;
  }
}

.bg-pad {
  color: #000;
  background: #fcf951;
  margin: -.25rem;
  padding: 0 .25rem;
}

.border-pad {
  color: #aaa;
  border: 1px solid #ccc;
  padding: .5rem 1rem;
}

.bdbc {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

@media screen and (width <= 992px) {
  .hide-mobile {
    display: none;
  }
}

.text-red {
  color: #ff5940;
}

.text-yellow {
  color: #fcf951;
}

.text-black {
  color: #000;
}

.display-3 {
  font-weight: 800;
  line-height: 1;

  @media screen and (width <= 575px) {
    & {
      font-size: 2rem !important;
    }
  }
}

.display-4 {
  font-weight: 800;
  line-height: 1;
}

@media screen and (width >= 1440px) {
  .display-4 {
    letter-spacing: -.035em;
    font-size: 3.5rem !important;
  }
}

.display-12 {
  letter-spacing: -.025em;
  font-weight: 600;
  line-height: 1;
}

@media screen and (width >= 992px) {
  .display-12 {
    font-size: 6.75rem !important;
  }
}

.display-14 {
  letter-spacing: -.025em;
  font-weight: 800;
  line-height: 1 !important;
}

@media screen and (width >= 992px) {
  .display-14 {
    font-size: 2.25rem !important;
  }
}

.display-14 a:hover {
  text-decoration: underline;
}

.display-16 {
  letter-spacing: -.025em;
  font-weight: 600;
  line-height: 1;
}

@media (width <= 991.98px) {
  .display-16 {
    line-height: 3.5rem;
    font-size: 3.75rem !important;
  }
}

@media screen and (width >= 992px) {
  .display-16 {
    font-size: 8.75rem !important;
  }
}

@media screen and (width >= 1440px) {
  .display-16 {
    font-size: 10.75rem !important;
  }
}

.display-20 {
  letter-spacing: 0;
  color: #000;
  font-weight: 600;
  line-height: 1;
}

@media (width <= 991.98px) {
  .display-16 {
    line-height: 1.25rem;
    font-size: 1.25rem !important;
  }
}

@media screen and (width >= 992px) {
  .display-16 {
    font-size: 1.25rem !important;
  }
}

@media screen and (width >= 1440px) {
  .display-16 {
    font-size: 1.25rem !important;
  }
}

.display-title-1 {
  letter-spacing: -.05em;
  font-weight: 800;
  line-height: 1;

  @media screen and (width <= 575px) {
    & {
      font-size: 2.5rem !important;
    }
  }

  @media screen and (width >= 576px) {
    & {
      font-size: 3.2rem !important;
    }
  }
}

@media screen and (width >= 768px) {
  .display-title-1 {
    letter-spacing: -.05em;
  }
}

.navbar-collapse.show .nav-fullscreen-lg {
  visibility: visible;
  opacity: 1;
  color: #fff;
}

.navbar-collapse.show .nav-fullscreen-lg .navbar-nav:before {
  width: 100%;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item .nav-link {
  opacity: 1;
  transform: none;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(0) .nav-link {
  transition-property: transform;
  transition-delay: .2s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:first-child .nav-link {
  transition-property: transform;
  transition-delay: .27s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(2) .nav-link {
  transition-property: transform;
  transition-delay: .34s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(3) .nav-link {
  transition-property: transform;
  transition-delay: .41s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(4) .nav-link {
  transition-property: transform;
  transition-delay: .48s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(5) .nav-link {
  transition-property: transform;
  transition-delay: .55s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(6) .nav-link {
  transition-property: transform;
  transition-delay: .62s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(7) .nav-link {
  transition-property: transform;
  transition-delay: .69s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(8) .nav-link {
  transition-property: transform;
  transition-delay: .76s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(9) .nav-link {
  transition-property: transform;
  transition-delay: .83s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(10) .nav-link {
  transition-property: transform;
  transition-delay: .9s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(11) .nav-link {
  transition-property: transform;
  transition-delay: .97s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(12) .nav-link {
  transition-property: transform;
  transition-delay: 1.04s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(13) .nav-link {
  transition-property: transform;
  transition-delay: 1.11s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(14) .nav-link {
  transition-property: transform;
  transition-delay: 1.18s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(15) .nav-link {
  transition-property: transform;
  transition-delay: 1.25s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(16) .nav-link {
  transition-property: transform;
  transition-delay: 1.32s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(17) .nav-link {
  transition-property: transform;
  transition-delay: 1.39s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(18) .nav-link {
  transition-property: transform;
  transition-delay: 1.46s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(19) .nav-link {
  transition-property: transform;
  transition-delay: 1.53s;
}

.navbar-collapse.show .nav-fullscreen-lg .nav-item:nth-child(20) .nav-link {
  transition-property: transform;
  transition-delay: 1.6s;
}

.nav-fullscreen-lg {
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  background: #000c;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  transition: all .3s;
  display: flex;
  position: fixed;
  inset: 0;
}

.nav-fullscreen-lg .nav-bg {
  flex: 1 0;
  order: 1;
  height: 100%;
}

.nav-fullscreen-lg .navbar-nav {
  padding: 3rem var(--margin-x);
  z-index: 1;
  flex-direction: column;
  flex: none;
  order: 2;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 152px;
  display: flex;
  position: relative;
  right: 0;
}

@media screen and (width >= 992px) {
  .nav-fullscreen-lg .navbar-nav {
    max-width: 44vw;
  }
}

@media screen and (width <= 991px) {
  .nav-fullscreen-lg .navbar-nav {
    max-width: 90vw;
  }
}

.nav-fullscreen-lg .navbar-nav:before {
  content: "";
  z-index: -1;
  background: #141516;
  width: 0%;
  transition: all .6s cubic-bezier(.25, .75, .2, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.nav-fullscreen-lg .nav-item {
  font-weight: 600;
  line-height: .8;
  margin-right: 0 !important;

  @media (width <= 576px) {
    & {
      font-size: 1.2rem !important;
    }
  }

  @media (width >= 577px) {
    & {
      font-size: 2rem !important;
    }
  }
}

.nav-fullscreen-lg .nav-item .nav-link {
  opacity: 1;
  transform-origin: -96px;
  transition: transform 1.2s;
  display: inline-block;
  transform: translateY(100%);
}

.nav-fullscreen-lg .nav-link {
  color: currentColor;
  font-size: inherit;
}

.nav-fullscreen-lg .nav-link:hover {
  color: currentColor;
}

.navbar-toggler:not(.collapsed) {
  color: #fff;
}

.navbar-light .navbar-toggler:not(.collapsed), .navbar-light .nav-fullscreen-lg .navbar-nav {
  color: #141516;
}

.navbar-light .nav-fullscreen-lg .navbar-nav:before {
  background: #fff;
}

.slider-simple-a .slider-btns {
  margin-left: 170px;

  @media screen and (width >= 1200px) {
    & {
      margin-left: 134px;
    }
  }

  @media screen and (width >= 1400px) {
    & {
      margin-left: 96px;
    }
  }
}

.icon-menu-a {
  color: currentColor;
  opacity: 1;
  background: none;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.icon-menu-a .text {
  text-align: left;
  text-transform: uppercase;
  color: currentColor;
  padding-right: 8px;
  font-size: 14px;
  line-height: 1;
  transition: all .3s;
  position: absolute;
  top: 13px;
  right: 100%;
}

@media (width >= 768px) {
  .icon-menu-a .text {
    top: -100%;
    left: -20%;
    right: auto;
    transform: rotate(-90deg);
  }
}

.icon-menu-a .text.hide-menu-visible {
  visibility: visible;
  opacity: 1;
  top: -100%;
}

.icon-menu-a .text.show-menu-visible {
  visibility: hidden;
  opacity: 0;
  top: -200%;
}

.icon-menu-a .bars {
  flex-direction: column;
  width: 40px;
  display: flex;
  position: relative;
  transform: translateY(-1px);
}

.icon-menu-a .bar1, .icon-menu-a .bar2 {
  background: #fff;
  width: 100%;
  height: 4px;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 50%;
}

.icon-menu-a .bar1 {
  width: 100%;
  transform: translateY(-6px);
}

.icon-menu-a .bar2 {
  width: 100%;
  transform: translateY(6px);
}

.navbar-toggler[aria-expanded="true"] .icon-menu-a .text.show-menu-visible, .icon-menu-a.menu-visible .text.show-menu-visible {
  visibility: visible;
  opacity: 1;
  top: -100%;
}

.navbar-toggler[aria-expanded="true"] .icon-menu-a .text.hide-menu-visible, .icon-menu-a.menu-visible .text.hide-menu-visible {
  visibility: hidden;
  opacity: 0;
  top: -200%;
}

.navbar-toggler[aria-expanded="true"] .icon-menu-a .bar1, .icon-menu-a.menu-visible .bar1 {
  transform-origin: center;
  width: 100%;
  transform: rotate(45deg)scaleX(.8);
}

.navbar-toggler[aria-expanded="true"] .icon-menu-a .bar2, .icon-menu-a.menu-visible .bar2 {
  transform-origin: center;
  width: 100%;
  transform: rotate(-45deg)scaleX(.8);
}

.navbar-a.navbar-dark .navbar-toggler.collapsed .bar1, .navbar-a.navbar-dark .navbar-toggler.collapsed .bar2, .navbar-a.navbar-dark .navbar-toggler.collapsed .bar3 {
  box-shadow: 0 4px #000;
}

.addr-line {
  line-height: 1.5;
}

.section-color-1 {
  background-color: var(--bg-color);
  color: var(--fg-color);
}

.section-color-2 {
  color: var(--fg-color);
  background-color: #f3f3f3;
}

.section-color-3 {
  color: #fff;
  background-color: #3d3d3d;
}

.section-color-3 .text-stroke {
  -webkit-text-stroke: 1px var(--white, #fff);
  color: #0000;
}

.section-color-10 {
  color: #fff;
  background-color: #000;
}

.section-color-10 .text-stroke {
  -webkit-text-stroke: 1px var(--white, #fff);
  color: #0000;
}

.section-color-11 {
  color: #000;
  background-color: #eaeae1;
}

.section-color-11 .text-stroke {
  -webkit-text-stroke: 1px var(--white, #000);
  color: #0000;
}

.content-white {
  color: #fff;
}

.content-white .text-stroke {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-stroke: 1px var(--white, #fff);
  color: #0000;
}

.navbar-a .navbar-nav .nav-item {
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  font-size: 2rem;
  line-height: 1;
}

.section-a .section-body {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (width >= 992px) {
  .display-title-1 {
    letter-spacing: -.05em;
  }

  @media screen and (width >= 1440px) {
    .display-title-1 {
      letter-spacing: -.05em;
      font-size: 6rem !important;
    }
  }

  .display-title-2 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.1;
  }

  @media screen and (width >= 768px) {
    .display-title-2 {
      margin-bottom: 1rem;
      font-size: 4.5rem;
    }
  }

  @media screen and (width >= 992px) {
    .display-title-2 {
      font-size: 8rem;
    }
  }

  .display-subtitle-1 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
  }

  @media screen and (width >= 768px) {
    .display-subtitle-1 {
      margin-bottom: 1rem;
      font-size: 2.5rem;
    }
  }

  @media screen and (width >= 992px) {
    .display-subtitle-1 {
      font-size: 5rem;
    }
  }

  .display-subtitle-2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  @media screen and (width >= 992px) {
    .display-subtitle-2 {
      font-size: 4rem;
    }
  }

  .display-subtitle-3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .text-muted-1 {
    opacity: .5;
  }

  .text-muted-2 {
    opacity: .666667;
  }

  .text-muted-3 {
    opacity: .333333;
  }

  .text-spaced {
    letter-spacing: .25em;
  }

  .inline-text {
    display: inline;
  }

  .text-stroke {
    -webkit-text-stroke: .75px black;
    -webkit-text-stroke: .75px var(--black, #fff);
    color: #0000;
    letter-spacing: -.035em;
  }

  .text-stroke-white {
    -webkit-text-stroke: .75px white;
    -webkit-text-stroke: .75px var(--white, #fff);
    color: #0000;
    letter-spacing: -.035em;
  }

  .text-#FCF951 {
    color: #fcf951;
  }

  .text-shadow {
    text-shadow: 0 1px 2px #0003;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .nowrap {
    white-space: nowrap;
  }

  .lh-sm {
    line-height: 1.2;
  }

  .lh-md {
    line-height: 1.4;
  }

  .lh-lg {
    line-height: 1.7;
  }

  .opacity-0 {
    opacity: 0 !important;
  }

  .opacity-1 {
    opacity: .1 !important;
  }

  .opacity-2 {
    opacity: .2 !important;
  }

  .opacity-3 {
    opacity: .3 !important;
  }

  .opacity-4 {
    opacity: .4 !important;
  }

  .opacity-5 {
    opacity: .5 !important;
  }

  .opacity-6 {
    opacity: .6 !important;
  }

  .opacity-7 {
    opacity: .7 !important;
  }

  .opacity-8 {
    opacity: .8 !important;
  }

  .opacity-9 {
    opacity: .9 !important;
  }

  .opacity-10 {
    opacity: 1 !important;
  }

  .rounded-xs {
    border-radius: 4px !important;
  }

  .rounded-sm {
    border-radius: 8px !important;
  }

  .rounded-md {
    border-radius: 16px !important;
  }

  .rounded-lg {
    border-radius: 32px !important;
  }

  .shadow-sm {
    box-shadow: 0 0 8px #0000004d;
  }

  .shadow-md {
    box-shadow: 0 0 16px #0000004d;
  }

  .shadow-lg {
    box-shadow: 0 0 40px #0000004d;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .pos-rel {
    position: relative;
  }

  .pos-abs {
    position: absolute;
  }

  .pos-fix {
    position: fixed;
  }

  .max-w-small, .width-small {
    max-width: 40rem !important;
  }

  .max-w-medium, .width-medium {
    max-width: 48rem !important;
  }

  .max-w-large, .width-large {
    max-width: 66rem !important;
  }

  .max-w-full, .width-full {
    max-width: unset !important;
  }

  .fullscreen-h {
    min-height: 100vh;
    transition: all .3s;
  }

  @media screen and (width >= 992px) {
    .fullscreen-h-lg {
      min-height: 100vh;
    }
  }

  .h-100vh {
    height: 100vh !important;
  }

  .fullscreen-w {
    min-width: 100vw;
  }

  .content-full {
    width: 100%;
  }

  .mt-section {
    margin-top: var(--section-margin-y) !important;
  }

  @media screen and (width <= 576px) {
    .mt-sm-section {
      margin-top: var(--section-margin-y) !important;
    }
  }

  @media screen and (width >= 768px) {
    .mt-md-section {
      margin-top: var(--section-margin-y) !important;
    }
  }

  @media screen and (width >= 992px) {
    .mt-lg-section {
      margin-top: var(--section-margin-y) !important;
    }
  }

  .mb-section {
    margin-bottom: var(--section-margin-y) !important;
  }

  @media screen and (width >= 768px) {
    .mb-md-section {
      margin-bottom: var(--section-margin-y) !important;
    }
  }

  @media screen and (width >= 992px) {
    .mb-lg-section {
      margin-bottom: var(--section-margin-y) !important;
    }
  }

  .pt-list {
    padding-top: 5rem;
  }

  .g-list, .gy-list {
    --bs-gutter-y: 2rem;
  }

  @media screen and (width >= 992px) {
    .g-list, .gy-list {
      --bs-gutter-y: 4rem;
    }
  }

  @media screen and (width >= 1200px) {
    .g-list, .gy-list {
      --bs-gutter-y: 8rem;
    }
  }

  .g-list, .gx-list {
    --bs-gutter-x: 2rem;
  }

  @media screen and (width >= 992px) {
    .g-list, .gx-list {
      --bs-gutter-x: 4rem;
    }
  }

  @media screen and (width >= 1200px) {
    .g-list, .gx-list {
      --bs-gutter-x: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .g-list-lg, .gy-list-lg {
      --bs-gutter-y: 8rem;
    }

    .g-list-lg, .gx-list-lg {
      --bs-gutter-x: 8rem;
    }
  }

  .rellax-vert {
    transform: rotate(90deg);
  }

  .rellax-vert .rellax {
    transform: rotate(-90deg);
  }

  .anim-list-delay.row-cols-1 .col .item .img-anim {
    transition-delay: 0s;
  }

  @media screen and (width >= 768px) {
    .anim-list-delay.row-cols-md-2 .col:nth-child(odd) .item .img-anim {
      transition-delay: 0s;
    }

    .anim-list-delay.row-cols-md-2 .col:nth-child(2n+2) .item .img-anim {
      transition-delay: .15s;
    }
  }

  @media screen and (width >= 992px) {
    .anim-list-delay.row-cols-lg-3 .col:nth-child(3n+1) .item .img-anim {
      transition-delay: 0s;
    }

    .anim-list-delay.row-cols-lg-3 .col:nth-child(3n+2) .item .img-anim {
      transition-delay: .15s;
    }

    .anim-list-delay.row-cols-lg-3 .col:nth-child(3n+3) .item .img-anim {
      transition-delay: .3s;
    }
  }

  .scroll-anim [data-anim-visible="fade"] {
    opacity: 0;
    transition: all 1.2s;
  }

  .scroll-anim .anim-visible[data-anim-visible="fade"] {
    opacity: 1;
  }

  .scroll-anim [data-anim-visible="scale-x"] {
    transform-origin: 0;
    transition: all .9s .15s;
    transition-timing-function: var(--transition-curve-1);
    transform: scaleX(0);
  }

  .scroll-anim .anim-visible[data-anim-visible="scale-x"] {
    transform: scaleX(1);
  }

  @media screen and (width >= 992px) {
    .scroll-anim [data-anim-visible="scale-x-2"] {
      transition-delay: .15s;
    }

    .scroll-anim [data-anim-visible="scale-x-2"] > * {
      clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
      transition: all .9s;
      transition-delay: inherit;
      transition-timing-function: var(--transition-curve-1);
    }

    .scroll-anim .anim-visible[data-anim-visible="scale-x-2"] > * {
      clip-path: polygon(0% 0%, 100% 0%, 100% 110%, 0% 110%);
    }
  }

  .scroll-anim [data-anim-visible="scale-y-2"] {
    opacity: 0;
    transition: all .9s;
  }

  @media screen and (width >= 992px) {
    .scroll-anim [data-anim-visible="scale-y-2"] {
      opacity: 1;
      transition: all .9s .15s;
      transition-timing-function: var(--transition-curve-1);
      transform: translateY(3rem);
    }

    .scroll-anim [data-anim-visible="scale-y-2"] > * {
      clip-path: polygon(0% 110%, 100% 110%, 100% 110%, 0% 110%);
      transition: all .9s;
      transition-delay: inherit;
      transition-timing-function: inherit;
    }
  }

  .scroll-anim .anim-visible[data-anim-visible="scale-y-2"] {
    opacity: 1;
  }

  @media screen and (width >= 992px) {
    .scroll-anim .anim-visible[data-anim-visible="scale-y-2"] {
      transform: none;
    }

    .scroll-anim .anim-visible[data-anim-visible="scale-y-2"] > * {
      clip-path: polygon(0% 0%, 100% 0%, 100% 110%, 0% 110%);
    }
  }

  .scroll-anim [data-anim-visible="fade-up"] {
    opacity: 0;
    transition: all .6s;
    transition-timing-function: var(--transition-curve-1);
    transform: translateY(64px);
  }

  .scroll-anim .anim-visible[data-anim-visible="fade-up"] {
    opacity: 1;
    transform: none;
  }

  .scroll-anim [data-anim-visible="zoom-in"] {
    opacity: 0;
    transition: all 1.2s;
    transform: scale(1.5);
  }

  .scroll-anim .anim-visible[data-anim-visible="zoom-in"] {
    opacity: 1;
    transform: none;
  }

  .scroll-anim [data-anim-visible="unmask"] {
    z-index: 0;
    position: relative;
  }

  .scroll-anim [data-anim-visible="unmask"]:before {
    content: "";
    transition: all .9s;
    transition-timing-function: var(--transition-curve-1);
    z-index: 1;
    background: #8b8b8b;
    height: 100%;
    position: absolute;
    inset: 0 0 auto;
  }

  .scroll-anim .anim-visible[data-anim-visible="unmask"] {
    opacity: 1;
  }

  .scroll-anim .anim-visible[data-anim-visible="unmask"]:before {
    height: 0%;
  }

  .scroll-anim [data-anim-visible="by-letter"] {
    opacity: 0;
  }

  .scroll-anim [data-anim-visible="by-letter"] .word {
    white-space: nowrap;
    display: inline-block;
  }

  .scroll-anim [data-anim-visible="by-letter"] .letter {
    white-space: break-spaces;
    opacity: 0;
    transition: all .9s cubic-bezier(.25, .75, .2, 1);
    display: inline-block;
    transform: translateY(50%);
  }

  .scroll-anim .anim-visible[data-anim-visible="by-letter"] {
    opacity: 1;
  }

  .scroll-anim .anim-visible[data-anim-visible="by-letter"] .letter {
    opacity: 1;
    transform: none;
  }

  .hide-on-scroll, .hide-on-scroll-sm, .hide-on-scroll-md, .hide-on-scroll-lg {
    visibility: visible;
    opacity: 1;
    transition: all .3s;
  }

  body.scrolled .hide-on-scroll {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-24px);
  }

  @media screen and (width >= 576px) {
    body.scrolled .hide-on-scroll-sm {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-24px);
    }
  }

  @media screen and (width >= 768px) {
    body.scrolled .hide-on-scroll-md {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-24px);
    }
  }

  @media screen and (width >= 992px) {
    body.scrolled .hide-on-scroll-lg {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-24px);
    }
  }

  .fullpage-anim .section .section-title.title-anim {
    opacity: 0;
    transition: all .3s ease-in-out;
    transform: scale(1.2)translateY(-50%);
  }

  .fullpage-anim .section.active .section-title.title-anim {
    opacity: 1;
    transition-delay: .6s;
    transform: translateY(-50%);
  }

  @media (width >= 768px) {
    .fullpage-anim .section .anim.translateUp {
      transition: all .3s ease-in-out;
      transform: translateY(48px);
    }

    .fullpage-anim .section.active .anim.translateUp {
      transition-delay: .3s;
      transform: none;
    }

    .fullpage-anim .section .anim.translateDown {
      transition: all .3s ease-in-out;
      transform: translateY(-48px);
    }

    .fullpage-anim .section.active .anim.translateDown {
      transition-delay: .3s;
      transform: none;
    }
  }

  @media (width >= 601px) {
    .fullpage-anim .section .anim .anim-wrapper {
      display: block;
      overflow: hidden;
    }

    .fullpage-anim .section .anim .row.anim-list > .col {
      opacity: 1;
      transition: all .6s;
    }

    .fullpage-anim .section .anim .row.anim-list > .col:first-child, .fullpage-anim .section .anim .row.anim-list > .col:nth-child(2), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(3), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(4), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(5), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(6), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(7), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(8), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(9), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(10), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(11), .fullpage-anim .section .anim .row.anim-list > .col:nth-child(12) {
      opacity: 0;
      transform: translateY(48px);
    }

    .fullpage-anim .section .anim .anim-1, .fullpage-anim .section .anim .anim-2, .fullpage-anim .section .anim .anim-3, .fullpage-anim .section .anim .anim-4 {
      opacity: 0;
      transition: all .3s;
      position: relative;
      transform: translateY(20px);
    }

    .fullpage-anim .section .anim .zoomout-1 {
      opacity: 0;
      transition: all .6s;
      transform: scale(1.1);
    }

    .fullpage-anim .section .anim .fade-2, .fullpage-anim .section .anim .fade-3, .fullpage-anim .section .anim .fade-4, .fullpage-anim .section .anim .fade-1 {
      opacity: 0;
      transition: all .6s;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col, .fullpage-anim .section.active .anim .row.anim-list > .col:first-child {
      opacity: 1;
      transition-delay: .6s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(2) {
      opacity: 1;
      transition-delay: .75s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(3) {
      opacity: 1;
      transition-delay: .9s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(4) {
      opacity: 1;
      transition-delay: 1.05s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(5) {
      opacity: 1;
      transition-delay: 1.2s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(6) {
      opacity: 1;
      transition-delay: 1.35s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(7) {
      opacity: 1;
      transition-delay: 1.5s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(8) {
      opacity: 1;
      transition-delay: 1.65s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(9) {
      opacity: 1;
      transition-delay: 1.8s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(10) {
      opacity: 1;
      transition-delay: 1.95s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(11) {
      opacity: 1;
      transition-delay: 2.1s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .row.anim-list > .col:nth-child(12) {
      opacity: 1;
      transition-delay: 2.25s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .anim-1 {
      opacity: 1;
      transition-delay: .3s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .anim-2 {
      opacity: 1;
      transition-delay: .5s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .anim-3 {
      opacity: 1;
      transition-delay: .7s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .anim-4 {
      opacity: 1;
      transition-delay: .9s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .zoomout-1, .fullpage-anim .section.active .anim .fade-1 {
      opacity: 1;
      transition-delay: .3s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .fade-2 {
      opacity: 1;
      transition-delay: .5s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .fade-3 {
      opacity: 1;
      transition-delay: .7s;
      transform: none;
    }

    .fullpage-anim .section.active .anim .fade-4 {
      opacity: 1;
      transition-delay: .9s;
      transform: none;
    }
  }

  .navbar-a {
    padding: 8px 24px;
    padding: 8px var(--margin-x-sm);
    z-index: 10;
    opacity: 1;
    visibility: visible;
    width: 100%;
    margin-top: 0;
    padding-top: 16px;
    transition: all .6s;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
  }

  .navbar-a:before {
    content: "";
    z-index: -1;
    backdrop-filter: blur(6px);
    opacity: 0;
    background: #0003;
    transition: all .3s;
    position: absolute;
    inset: 0;
  }

  @media screen and (width >= 768px) {
    .navbar-a {
      padding: 16px 32px;
    }
  }

  @media screen and (width >= 992px) {
    .navbar-a {
      padding: 0 64px;
      padding: 0 var(--margin-x);
      height: 0;
      margin-top: 32px;
    }

    .navbar-a > * {
      height: 0;
    }
  }

  .navbar-a .navbar-brand {
    color: currentColor;
    padding: 0;
    display: flex;
  }

  .navbar-a .navbar-brand .light-logo {
    display: block;
  }

  .navbar-a .navbar-brand .dark-logo {
    display: none;
  }

  .navbar-a .navbar-brand .text {
    color: currentColor;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 1.125rem;
    line-height: 1.2;
    display: flex;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-brand .text {
      line-height: 1.375;
    }
  }

  .navbar-a .navbar-brand .text .line {
    display: block;
  }

  .navbar-a .navbar-brand .text .line.sub {
    opacity: .5;
    font-size: .875rem;
  }

  .navbar-a .navbar-toggler {
    z-index: 100;
    color: currentColor;
    border: none;
    border-radius: 8px;
    justify-content: flex-end;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-left: 32px;
    padding: 0;
    display: flex;
    position: relative;
  }

  .navbar-a .navbar-toggler:focus, .navbar-a .navbar-toggler:hover {
    box-shadow: none;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-toggler {
      box-sizing: border-box;
      justify-content: flex-end;
      padding-left: 16px;
      padding-right: 0;
    }

    .navbar-a .navbar-toggler.menu-icon-left-center {
      width: auto;
      margin-left: 0;
      padding-left: 0;
      padding-right: 16px;
      position: fixed;
      top: 50%;
      left: 64px;
      transform: translateY(-50%);
    }
  }

  .navbar-a .navbar-menu-wrapper {
    padding: 24px;
    padding: var(--margin-x-sm);
    backdrop-filter: blur(24px);
    height: calc(var(--vh, 1vh) * 100);
    z-index: -1;
    background: #14151680;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-top: 80px;
    transition: all .45s cubic-bezier(.25, .75, .2, 1);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  @media screen and (width >= 768px) {
    .navbar-a .navbar-menu-wrapper {
      padding: 32px;
      padding: var(--margin-x-md);
      padding-top: 120px;
    }
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-menu-wrapper {
      right: var(--margin-x);
      z-index: 0;
      overflow: initial;
      backdrop-filter: initial;
      background: none;
      flex-direction: row;
      justify-content: flex-end;
      height: auto;
      padding: 0;
      position: absolute;
      top: 56px;
      left: auto;
    }
  }

  .navbar-a .navbar-menu-wrapper .nav-body {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    display: flex;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-menu-wrapper .nav-body {
      justify-content: flex-end;
      align-items: center;
    }
  }

  .navbar-a .navbar-menu-wrapper .nav-footer-md {
    place-self: flex-end;
    width: 100%;
    margin-top: 1.25rem;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-menu-wrapper .nav-footer-md {
      display: none;
    }
  }

  .navbar-a .navbar-collapse.collapsing {
    transition: all;
    display: block;
    height: 100% !important;
  }

  .navbar-a .navbar-collapse {
    display: block;
  }

  .navbar-a .navbar-collapse.collapse {
    display: block;
    overflow-x: hidden;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-collapse.collapse {
      overflow: initial;
    }
  }

  .navbar-a .navbar-collapse.collapse .navbar-menu-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    transform: translateX(40px);
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-collapse.collapse .navbar-menu-wrapper {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }

  .navbar-a .navbar-collapse.collapse.show {
    transition: all .3s;
  }

  .navbar-a .navbar-collapse.collapse.show .navbar-menu-wrapper {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .navbar-a .navbar-nav {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-nav {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .navbar-a .navbar-nav .nav-link {
    color: currentColor;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-a .navbar-nav .nav-link.dropdown-toggle {
    padding-right: 2em !important;
  }

  .navbar-a .navbar-nav .nav-link:hover {
    color: #fff;
  }

  .navbar-a .navbar-nav .nav-item {
    letter-spacing: -.016rem;
    font-size: 1.25rem;
  }

  .navbar-a .navbar-nav .nav-item.active a, .navbar-a .navbar-nav .nav-item.active .nav-item {
    color: #fff;
  }

  .navbar-a .navbar-nav .nav-item .nav-link {
    padding-top: 0 !important;
  }

  .navbar-a .navbar-nav .nav-item .nav-link:hover {
    opacity: 1;
  }

  .navbar-a .navbar-nav .nav-item .btn {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
  }

  @media screen and (width >= 992px) {
    .navbar-a .navbar-nav .nav-item .btn {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  .navbar-a .nav-menu-top {
    transition: all .3s;
  }

  .navbar-a .nav-cta {
    top: calc(var(--margin-y)  + 48px);
    left: var(--margin-x);
    align-items: flex-start;
    position: fixed;
    right: 0;
  }

  @media screen and (width >= 992px) {
    .navbar-a .nav-cta {
      top: calc(var(--margin-y)  + 4px);
      right: calc(var(--margin-x)  + 40px + 48px);
      margin-left: 2.5rem;
      left: auto;
    }
  }

  .navbar-a .dropdown-menu {
    color: currentColor;
    background-color: #ffffff0d;
    border: 2px solid #fff3;
    border-radius: 16px;
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  @media screen and (width >= 992px) {
    .navbar-a .dropdown-menu {
      backdrop-filter: blur(6px);
      visibility: hidden;
      opacity: 0;
      margin-top: 0;
      margin-bottom: 0;
      transition: all .3s cubic-bezier(.25, .75, .2, 1);
      display: block;
      left: auto;
      right: 0;
      transform: scale(1.1);
      box-shadow: 0 0 8px #ffffff1a;
    }

    .navbar-a .dropdown-menu.show {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }

  .navbar-a .dropdown-item {
    color: currentColor;
    padding: .5rem 1.25rem;
    font-size: 1.25rem;
  }

  .navbar-a .dropdown-item:hover, .navbar-a .dropdown-item:focus {
    background-color: #ffffff1a;
  }

  @media screen and (width >= 992px) {
    .navbar-a .dropdown-item {
      font-size: 1rem;
    }
  }

  .navbar-a.navbar-dark {
    color: #fff;
    background-color: initial;
  }

  .navbar-a.navbar-dark .navbar-toggler {
    color: #fff;
  }

  .navbar-a.navbar-dark .navbar-toggler:before {
    z-index: 0;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 4px;
  }

  .navbar-a.navbar-dark .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-a.navbar-dark .navbar-nav .nav-link {
    color: #ffffffb3;
  }

  .navbar-a.navbar-light {
    color: #141516;
    background-color: initial;
  }

  .navbar-a.navbar-light .navbar-menu-wrapper {
    background-color: #ffffffb3;
  }

  .navbar-a.navbar-light .navbar-toggler {
    color: #141516;
  }

  @media screen and (width >= 768px) {
    .navbar-a.navbar-light .navbar-toggler.collapsed .bar1, .navbar-a.navbar-light .navbar-toggler.collapsed .bar2, .navbar-a.navbar-light .navbar-toggler.collapsed .bar3 {
      box-shadow: 0 2px #ffffffb3;
    }
  }

  .navbar-a.navbar-light .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-a.navbar-light .navbar-nav .nav-link {
    color: #141516b3;
  }

  .navbar-a.hide-header {
    transform: translateY(-100%);
  }

  @media screen and (width >= 768px) {
    .navbar-a.hide-header {
      transform: none;
    }
  }

  body.menu-visible .navbar-a.hide-header {
    transform: none;
  }

  @media screen and (width <= 576px) {
    body.scrolled .navbar-a {
      padding-top: 8px;
    }

    body.scrolled .navbar-a:before {
      opacity: 1;
    }
  }

  @media screen and (width <= 1024px) {
    #fp-nav {
      visibility: hidden;
    }
  }

  #fp-nav.right {
    right: var(--margin-x);
    margin-right: -6px;
    position: absolute;
  }

  #fp-nav span, .fp-slidesNav span {
    border-color: #fff;
    width: 10px;
    height: 10px;
    transition: all .3s;
  }

  #fp-nav ul li a span {
    background: #fff;
  }

  #fp-nav li, .fp-slidesNav li {
    width: 10px;
    height: 10px;
    margin: 16px;
    display: block;
    position: relative;
  }

  #fp-nav span:after, .fp-slidesNav span:after {
    content: "";
    background: #ffffff26;
    border-radius: 40px;
    transition: all .3s;
    display: block;
    position: absolute;
    inset: 0;
  }

  #fp-nav li .active span, .fp-slidesNav .active span {
    background: #fff;
    position: absolute;
  }

  #fp-nav li .active span:after, .fp-slidesNav .active span:after {
    border-color: #fff;
    inset: -4px;
  }

  #fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
  }

  #fp-nav ul li a {
    opacity: .2;
  }

  #fp-nav ul li a:hover {
    opacity: .6;
  }

  #fp-nav ul li a.active, #fp-nav ul li a.active:hover {
    opacity: 1;
  }

  .nav-dot-container {
    right: var(--margin-x-sm);
    z-index: 5;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (width >= 768px) {
    .nav-dot-container {
      right: var(--margin-x-md);
    }
  }

  @media (width >= 992px) {
    .nav-dot-container {
      right: var(--margin-x);
    }
  }

  .nav-dots ul, .nav-dots li {
    margin: 0;
    list-style: none;
  }

  .nav-dots li, .nav-dots .nav-item {
    margin: 1rem 0;
  }

  .nav-dots a, .nav-dots .nav-link {
    cursor: pointer;
    background: #ffffff4d;
    border-radius: 60px;
    width: .5rem;
    height: .5rem;
    padding: 0;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    display: block;
    position: relative;
  }

  .nav-dots a:before, .nav-dots a:after, .nav-dots .nav-link:before, .nav-dots .nav-link:after {
    content: "";
    border-radius: inherit;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: -4px;
  }

  .nav-dots a:after, .nav-dots .nav-link:after {
    background: #fff3;
    transform: scale(0);
  }

  .nav-dots li.active a, .nav-dots .nav-item.active a, .nav-dots a:hover, .nav-dots .nav-link:hover {
    background: #fff;
  }

  .nav-dots li.active a:after, .nav-dots .nav-item.active a:after, .nav-dots a:hover:after, .nav-dots .nav-link:hover:after {
    transform: scale(1);
  }

  .nav-dots li.active a:hover:after, .nav-dots li.active .nav-link:hover:after {
    background: #fffc;
  }

  .nav-sidebar {
    top: 50%;
    left: 64px;
    left: var(--margin-x);
    z-index: 2;
    position: fixed;
    transform: translateY(-50%);
  }

  .nav-sidebar .nav-wrapper {
    margin: 0;
  }

  .nav-sidebar ul, .nav-sidebar li {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }

  .nav-sidebar a, .nav-sidebar .nav-link {
    color: #fff;
    align-items: center;
    padding: 8px 8px 8px 0;
    display: flex;
  }

  .nav-sidebar a .icon, .nav-sidebar .nav-link .icon {
    z-index: 0;
    background: #fff3;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
    font-size: 1rem;
    display: flex;
    position: relative;
  }

  .nav-sidebar a .icon:before, .nav-sidebar .nav-link .icon:before {
    z-index: -1;
    content: "";
    background: #fff;
    border-radius: 2rem;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0;
    transform: scale(0);
  }

  .nav-sidebar a .text, .nav-sidebar .nav-link .text {
    opacity: .5;
  }

  .nav-sidebar a:hover .icon, .nav-sidebar .active .icon {
    color: #272123;
  }

  .nav-sidebar a:hover .icon:before, .nav-sidebar .active .icon:before {
    transform: scale(1);
  }

  .nav-sidebar a:hover .text, .nav-sidebar .active .text {
    opacity: 1;
  }

  .nav-social-a ul, .nav-social-a li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .nav-social-a ul {
    display: flex;
  }

  .nav-social-a ul li {
    margin-right: 1rem;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .nav-social-a ul li {
      margin-right: 1.5rem;
    }
  }

  .nav-social-a ul li:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  .nav-social-a a, .nav-social-a .btn {
    min-height: unset;
    color: currentColor;
    padding: 0;
    line-height: 1;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
  }

  .nav-social-a a:hover, .nav-social-a .btn:hover {
    opacity: .5;
  }

  .nav-social-a .icon {
    font-size: 28px;
    line-height: 1;
  }

  .nav-social-b ul, .nav-social-b li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .nav-social-b ul {
    display: flex;
  }

  .nav-social-b ul li {
    margin-right: 3rem;
    position: relative;
  }

  .nav-social-b ul li:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  .nav-social-b a, .nav-social-b .btn {
    min-height: unset;
    color: currentColor;
    opacity: .7;
    padding: 0 0 .25rem;
    line-height: 1;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
  }

  .nav-social-b a:hover, .nav-social-b .btn:hover {
    opacity: 1;
  }

  .nav-social-b .icon {
    font-size: 28px;
    line-height: 1;
  }

  .nav-list ul, .nav-list li {
    list-style: none;
  }

  .nav-list a, .nav-list .nav-link {
    color: currentColor;
    margin: .125rem 0;
    padding: .125rem 0;
    display: inline-block;
    position: relative;
  }

  .nav-list a:before, .nav-list .nav-link:before {
    content: "";
    opacity: 0;
    border-bottom: 2px solid;
    width: 0%;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .nav-list a:hover:before, .nav-list .nav-link:hover:before {
    opacity: 1;
    width: 100%;
  }

  .nav-list a:hover, .nav-list .nav-link:hover {
    color: currentColor;
    text-decoration: none;
  }

  .nav-list.list-inline .nav-item {
    margin-right: 1.5rem;
  }

  .nav-list.list-inline .nav-item:last-child {
    margin-right: 0;
  }

  .icon-sm {
    vertical-align: text-bottom;
    font-size: 1.25em;
    line-height: .75;
  }

  .icon-md {
    font-size: 32px;
    line-height: 1;
  }

  .icon-lg {
    font-size: 40px;
  }

  .icon-box-md {
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    display: inline-flex;
  }

  .icon-box-lg {
    justify-content: center;
    align-items: center;
    min-width: 64px;
    min-height: 64px;
    display: inline-flex;
  }

  .icon-img-md {
    -o-object-fit: cover;
    object-fit: cover;
    width: 48px;
    height: 48px;
  }

  .icon-img-lg {
    -o-object-fit: cover;
    object-fit: cover;
    width: 120px;
    height: 120px;
  }

  .icon-arrow-a {
    --arrow-length: 1rem;
    --arrow-size: 3.5rem;
    --arrow-thick: 2px;
    --arrow-color: currentColor;
    width: var(--arrow-size);
    height: var(--arrow-size);
    display: inline-flex;
    position: relative;
  }

  .icon-arrow-a .icon-border {
    width: var(--arrow-size);
    height: var(--arrow-size);
    border: var(--arrow-thick) solid var(--arrow-color);
    border-radius: var(--arrow-size);
    opacity: .5;
    transition: all .6s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon-arrow-a .arrow-right {
    width: 100%;
    height: 100%;
    transition: all .3s;
    display: flex;
    position: relative;
  }

  .icon-arrow-a .arrow-right:after {
    content: "";
    width: calc(.7071 * var(--arrow-length));
    height: calc(.7071 * var(--arrow-length));
    border-right: var(--arrow-thick) solid var(--arrow-color);
    border-bottom: var(--arrow-thick) solid var(--arrow-color);
    background-color: #0000;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translateX(-50%)translateY(-50%)rotate(-45deg);
  }

  .icon-arrow-a .arrow-right.r45 {
    transform: rotate(-45deg);
  }

  .icon-arrow-a .arrow-left {
    width: 100%;
    height: 100%;
    transition: all .3s;
    display: flex;
    position: relative;
  }

  .icon-arrow-a .arrow-left:after {
    content: "";
    width: calc(.7071 * var(--arrow-length));
    height: calc(.7071 * var(--arrow-length));
    border-top: var(--arrow-thick) solid var(--arrow-color);
    border-left: var(--arrow-thick) solid var(--arrow-color);
    background-color: #0000;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translateX(-50%)translateY(-50%)rotate(-45deg);
  }

  .icon-arrow-a .arrow-left.r45 {
    transform: rotate(-45deg);
  }

  .icon-arrow-a.icon-fit {
    width: 1em !important;
    height: 1em !important;
  }

  .btn-anim:hover .icon-arrow-a .icon-border {
    opacity: 0;
    transform: scale(.5);
  }

  .btn-anim:hover .icon-arrow-a .arrow-right, .btn-anim:hover .icon-arrow-a .arrow-right.r45 {
    transform: scale(1.5);
  }

  .icon-arrow-b {
    --arrow-thick: 8px;
    --arrow-color: currentColor;
    width: 1em;
    height: 1em;
    display: inline-flex;
    position: relative;
  }

  .icon-arrow-b .arrow-right {
    width: 100%;
    height: 100%;
    transition: all .3s;
    display: flex;
    position: relative;
  }

  .icon-arrow-b .arrow-right:after {
    content: "";
    border-right: var(--arrow-thick) solid var(--arrow-color);
    border-bottom: var(--arrow-thick) solid var(--arrow-color);
    background-color: #0000;
    width: .7071em;
    height: .7071em;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translateX(-50%)translateY(-50%)rotate(-45deg);
  }

  .icon-arrow-b .arrow-right.r45 {
    transform: rotate(-45deg);
  }

  .icon-arrow-b .arrow-left {
    width: 100%;
    height: 100%;
    transition: all .3s;
    display: flex;
    position: relative;
  }

  .icon-arrow-b .arrow-left:after {
    content: "";
    border-top: var(--arrow-thick) solid var(--arrow-color);
    border-left: var(--arrow-thick) solid var(--arrow-color);
    background-color: #0000;
    width: .7071em;
    height: .7071em;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translateX(-50%)translateY(-50%)rotate(-45deg);
  }

  .icon-arrow-b .arrow-left.r45 {
    transform: rotate(-45deg);
  }

  .btn-anim:hover .icon-arrow-b .icon-border {
    opacity: 0;
    transform: scale(.5);
  }

  .btn-anim:hover .icon-arrow-b .arrow-right, .btn-anim:hover .icon-arrow-b .arrow-right.r45 {
    transform: scale(1.5);
  }

  .icon-menu-b {
    color: currentColor;
    opacity: 1;
    background: none;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
  }

  .icon-menu-b .text {
    text-align: left;
    text-transform: uppercase;
    color: currentColor;
    padding-right: 8px;
    font-size: 14px;
    line-height: 1;
    transition: all .3s;
    position: absolute;
    top: 13px;
    right: 100%;
  }

  @media (width >= 768px) {
    .icon-menu-b .text {
      top: -100%;
      left: -20%;
      right: auto;
      transform: rotate(-90deg);
    }
  }

  .icon-menu-b .text.hide-menu-visible {
    visibility: visible;
    opacity: 1;
    top: -100%;
  }

  .icon-menu-b .text.show-menu-visible {
    visibility: hidden;
    opacity: 0;
    top: -200%;
  }

  .icon-menu-b .bars {
    flex-direction: column;
    width: 24px;
    display: flex;
    position: relative;
    transform: translateY(-1px);
  }

  .icon-menu-b .bar1, .icon-menu-b .bar2, .icon-menu-b .bar3 {
    background: currentColor;
    width: 100%;
    height: 2px;
    transition: all .3s;
    display: flex;
    position: absolute;
    top: 50%;
  }

  .icon-menu-b .bar1 {
    transform: translateY(-8px);
  }

  .icon-menu-b .bar3 {
    transform: translateY(8px);
  }

  .icon-menu-b.bars-spaced .bar1 {
    transform: translateY(-16px);
  }

  .icon-menu-b.bars-spaced .bar3 {
    transform: translateY(16px);
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-b .text.show-menu-visible, .icon-menu-b.menu-visible .text.show-menu-visible {
    visibility: visible;
    opacity: 1;
    top: -100%;
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-b .text.hide-menu-visible, .icon-menu-b.menu-visible .text.hide-menu-visible {
    visibility: hidden;
    opacity: 0;
    top: -200%;
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-b .bar1, .icon-menu-b.menu-visible .bar1 {
    transform-origin: center;
    width: 100%;
    transform: rotate(45deg)scaleX(.8);
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-b .bar3, .icon-menu-b.menu-visible .bar3 {
    transform-origin: center;
    width: 100%;
    transform: rotate(-45deg)scaleX(.8);
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-b .bar2, .icon-menu-b.menu-visible .bar2 {
    transform: scaleX(0);
  }

  .icon-menu-c {
    color: currentColor;
    opacity: 1;
    background: none;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
  }

  .icon-menu-c .text {
    text-align: left;
    text-transform: uppercase;
    color: currentColor;
    padding-right: 8px;
    font-size: 14px;
    line-height: 1;
    transition: all .3s;
    position: absolute;
    top: 13px;
    right: 100%;
  }

  @media (width >= 768px) {
    .icon-menu-c .text {
      top: -100%;
      left: -20%;
      right: auto;
      transform: rotate(-90deg);
    }
  }

  .icon-menu-c .text.hide-menu-visible {
    visibility: visible;
    opacity: 1;
    top: -100%;
  }

  .icon-menu-c .text.show-menu-visible {
    visibility: hidden;
    opacity: 0;
    top: -200%;
  }

  .icon-menu-c .bars {
    flex-direction: column;
    width: 32px;
    display: flex;
    position: relative;
    transform: translateY(-1px);
  }

  .icon-menu-c .bar1, .icon-menu-c .bar2 {
    background: currentColor;
    width: 100%;
    height: 2px;
    transition: all .3s;
    display: flex;
    position: absolute;
    top: 50%;
  }

  .icon-menu-c .bar1 {
    width: 100%;
    transform: translateY(-6px);
  }

  .icon-menu-c .bar2 {
    width: 100%;
    transform: translateY(6px);
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-c .text.show-menu-visible, .icon-menu-c.menu-visible .text.show-menu-visible {
    visibility: visible;
    opacity: 1;
    top: -100%;
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-c .text.hide-menu-visible, .icon-menu-c.menu-visible .text.hide-menu-visible {
    visibility: hidden;
    opacity: 0;
    top: -200%;
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-c .bar1, .icon-menu-c.menu-visible .bar1 {
    transform-origin: center;
    width: 100%;
    transform: rotate(45deg)scaleX(.8);
  }

  .navbar-toggler[aria-expanded="true"] .icon-menu-c .bar2, .icon-menu-c.menu-visible .bar2 {
    transform-origin: center;
    width: 100%;
    transform: rotate(-45deg)scaleX(.8);
  }

  .btn-full {
    width: 100%;
  }

  .btn-solid {
    background: #bb2464;
    background: var(--primary);
    color: #fff;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding: .75rem 1.875rem;
    display: inline-flex;
    position: relative;
    overflow: hidden;
  }

  .btn-solid:before {
    content: "";
    opacity: .15;
    border-radius: inherit;
    background: currentColor;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0 100% 0 0;
  }

  .btn-solid:hover {
    background: #bb2464;
    background: var(--primary);
  }

  .btn-solid:hover:before {
    right: 0;
  }

  .btn-solid.btn-white {
    color: #bb2464;
    background: #fff;
  }

  .btn-solid-a {
    backdrop-filter: blur(4px);
    color: #fff;
    background-color: #9696961a;
    border: none;
    border-radius: .5rem;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    min-height: 3rem;
    font-size: 1em;
    display: inline-flex;
    position: relative;
  }

  .btn-solid-a:before {
    content: "";
    opacity: 0;
    background-color: #6464641a;
    border-radius: .5rem;
    transition: all .3s;
    position: absolute;
    inset: 0;
    transform: scale(.5);
  }

  .btn-solid-a .icon {
    font-size: 1.25em;
  }

  .btn-solid-a:hover {
    color: #fff;
  }

  .btn-solid-a:hover:before {
    opacity: 1;
    transform: scale(1);
  }

  .btn-inline {
    position: relative;
  }

  .btn-inline:before {
    content: "";
    opacity: 0;
    border-bottom: 2px solid;
    width: 0%;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .btn-inline:hover:before {
    opacity: 1;
    width: 100%;
  }

  .btn-inline-a {
    --btn-line-a-fg: currentColor;
    --btn-line-a-width: 2px;
    --btn-icon-size: 3.5rem;
    color: var(--btn-line-a-fg);
    transition: all .3s;
    transition-timing-function: var(--transition-curve-2);
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 1.25rem;
    text-decoration: none;
    display: inline-flex;
    position: relative;
  }

  .btn-inline-a .icon {
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    line-height: 1;
    display: inline-flex;
  }

  .btn-inline-a .icon-border {
    width: var(--btn-icon-size);
    height: var(--btn-icon-size);
    justify-content: center;
    align-items: center;
    transition: all .6s;
    display: flex;
    position: relative;
  }

  .btn-inline-a .icon-border > * {
    position: relative;
  }

  .btn-inline-a .icon-border:before {
    content: "";
    border: var(--btn-line-a-width) solid currentColor;
    border-radius: var(--btn-icon-size);
    opacity: .5;
    z-index: 0;
    transition: all .3s;
    transition-timing-function: var(--transition-curve-2);
    position: absolute;
    inset: 0;
  }

  .btn-inline-a .text ~ .icon, .btn-inline-a .icon ~ .text {
    margin-left: .8em;
  }

  .btn-inline-a.btn-lg {
    min-height: auto;
    padding: 0 0 .25em;
    font-size: 1.125rem;
  }

  .btn-inline-a.bdw-thin {
    --btn-line-a-width: 1px;
  }

  .btn-inline-a.bdw-medium {
    --btn-line-a-width: 2px;
  }

  .btn-inline-a.bdw-thick {
    --btn-line-a-width: 4px;
  }

  .btn-inline-a.active, .btn-inline-a:active, .btn-inline-a:focus, .btn-inline-a:hover {
    color: var(--btn-line-a-fg);
    opacity: 1;
    background: none;
    text-decoration: none;
  }

  .btn-inline-a.active .icon-border, .btn-inline-a:active .icon-border, .btn-inline-a:focus .icon-border, .btn-inline-a:hover .icon-border {
    transform: scale(1.4);
  }

  .btn-inline-a.active .icon-border:before, .btn-inline-a:active .icon-border:before, .btn-inline-a:focus .icon-border:before, .btn-inline-a:hover .icon-border:before {
    opacity: 0;
    transform: scale(.5);
  }

  .btn-line-a.btn-primary {
    --btn-line-a-fg: var(--primary);
  }

  .btn-line-a.btn-secondary {
    --btn-line-a-fg: var(--secondary);
  }

  .btn-line-a.btn-white {
    --btn-line-a-fg: var(--white);
  }

  .btn-line-a.btn-black {
    --btn-line-a-fg: var(--black);
  }

  .btn-outline {
    letter-spacing: normal;
    background: none;
    border: none;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    padding: .75em 1.75em;
    font-size: 1.25rem;
    line-height: 1;
    transition: all .3s;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 0 2px #ffffff80;
  }

  .btn-outline .text {
    z-index: 1;
  }

  .btn-outline .icon {
    justify-content: center;
    align-items: center;
    height: .5rem;
    line-height: 0;
    display: inline-flex;
  }

  .btn-outline .icon > * {
    font-size: 1.2em;
  }

  .btn-outline .icon ~ .text, .btn-outline .text ~ .icon {
    margin-left: 1em;
  }

  .btn-outline.border-thick, .btn-outline.border-thick:before, .btn-outline.border-thick:after {
    border-width: 3px;
  }

  .btn-outline.btn-lg {
    padding: 1em 1.875em;
    font-size: 1.5rem;
    line-height: 1;
  }

  .btn-outline.btn-sm {
    font-size: 1rem;
  }

  .btn-outline:before {
    content: "";
    opacity: 0;
    border-radius: inherit;
    background: currentColor;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: -2px 100% -2px -2px;
  }

  .btn-outline:active, .btn-outline:hover {
    opacity: .75;
  }

  .btn-outline:hover:before {
    right: -2px;
  }

  .btn-outline.btn-primary {
    color: #bb2464;
    box-shadow: inset 0 0 0 6px #bb246433;
  }

  .btn-outline.btn-primary:hover {
    box-shadow: inset 0 0 0 2px #bb2464;
  }

  .btn-outline.btn-secondary {
    color: #3d4963;
    box-shadow: inset 0 0 0 6px #3d496333;
  }

  .btn-outline.btn-secondary:hover {
    box-shadow: inset 0 0 0 2px #3d4963;
  }

  .btn-outline.btn-white {
    color: #fff;
    box-shadow: inset 0 0 0 6px #fff3;
  }

  .btn-outline.btn-white:hover {
    box-shadow: inset 0 0 0 2px #fff;
  }

  .btn-outline.btn-black {
    color: #000;
    opacity: 1;
    box-shadow: inset 0 0 0 6px #000;
  }

  .btn-outline.btn-black:hover {
    box-shadow: inset 0 0 0 6px #ff0;
  }

  .btn-outline-b {
    color: #fff;
    background: none;
    border: none;
    border-radius: 160rem;
    justify-content: center;
    align-items: center;
    height: 3.5em;
    padding: .75em 0 .75em 1.5em;
    font-size: 1rem;
    line-height: 1;
    transition: all .3s;
    display: inline-flex;
    position: relative;
  }

  .btn-outline-b .text {
    z-index: 1;
  }

  .btn-outline-b .icon {
    justify-content: center;
    align-items: center;
    height: .5rem;
    line-height: 0;
    display: inline-flex;
  }

  .btn-outline-b .icon > * {
    font-size: 1.2em;
  }

  .btn-outline-b .icon ~ .text, .btn-outline-b .text ~ .icon {
    margin-left: 1em;
  }

  .btn-outline-b.border-thick, .btn-outline-b.border-thick:before, .btn-outline-b.border-thick:after {
    border-width: 3px;
  }

  .btn-outline-b:before {
    content: "";
    opacity: .2;
    border-radius: inherit;
    border: 2px solid;
    border-right-color: #0000;
    width: 3.5em;
    transition: transform ease-in, border .2s ease-in, width .2s ease-in .2s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .btn-outline-b:active {
    opacity: .75;
  }

  .btn-outline-b:hover {
    color: currentColor;
    padding: .75em 1.875em;
  }

  .btn-outline-b:hover:before {
    border-right-color: currentColor;
    width: 100%;
    transition: transform .3s ease-in, border .2s ease-in .3s, width .2s ease-in .3s;
    transform: rotate(360deg);
  }

  .btn-underline-a, .navbar-a .navbar-nav .nav-item .nav-link {
    opacity: .7;
    background: none;
    border: none;
    padding: .225em 0;
    transition: all .3s;
    position: relative;
  }

  .btn-underline-a:before, .navbar-a .navbar-nav .nav-item .nav-link:before, .btn-underline-a:after, .navbar-a .navbar-nav .nav-item .nav-link:after {
    content: "";
    border-bottom: 2px solid;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: auto;
    bottom: .25em;
  }

  .btn-underline-a:before, .navbar-a .navbar-nav .nav-item .nav-link:before {
    left: 0;
    right: 100%;
  }

  .btn-underline-a:after, .navbar-a .navbar-nav .nav-item .nav-link:after {
    transition-delay: .2s;
    left: 0%;
    right: 0%;
  }

  .btn-underline-a.bd-thin:before, .navbar-a .navbar-nav .nav-item .bd-thin.nav-link:before, .btn-underline-a.bd-thin:after, .navbar-a .navbar-nav .nav-item .bd-thin.nav-link:after {
    border-width: 1px;
  }

  .btn-underline-a.btn-xl, .navbar-a .navbar-nav .nav-item .btn-xl.nav-link {
    padding: .25em 0;
  }

  .btn-underline-a.btn-xl:before, .navbar-a .navbar-nav .nav-item .btn-xl.nav-link:before, .btn-underline-a.btn-xl:after, .navbar-a .navbar-nav .nav-item .btn-xl.nav-link:after {
    bottom: 0;
  }

  .btn-underline-a:focus, .navbar-a .navbar-nav .nav-item .nav-link:focus, .btn-underline-a:active, .navbar-a .navbar-nav .nav-item .nav-link:active, .btn-underline-a:hover, .navbar-a .navbar-nav .nav-item .nav-link:hover {
    opacity: 1;
    box-shadow: none;
    color: currentColor;
    background: none;
    outline: none;
  }

  .btn-underline-a:focus:before, .navbar-a .navbar-nav .nav-item .nav-link:focus:before, .btn-underline-a:active:before, .navbar-a .navbar-nav .nav-item .nav-link:active:before, .btn-underline-a:hover:before, .navbar-a .navbar-nav .nav-item .nav-link:hover:before {
    transition-delay: .2s;
    right: 0%;
  }

  .btn-underline-a:focus:after, .navbar-a .navbar-nav .nav-item .nav-link:focus:after, .btn-underline-a:active:after, .navbar-a .navbar-nav .nav-item .nav-link:active:after, .btn-underline-a:hover:after, .navbar-a .navbar-nav .nav-item .nav-link:hover:after {
    transition-delay: 0s;
    left: 100%;
  }

  .btn-underline-a.btn-primary, .navbar-a .navbar-nav .nav-item .btn-primary.nav-link {
    color: #bb2464;
  }

  .btn-underline-a.btn-secondary, .navbar-a .navbar-nav .nav-item .btn-secondary.nav-link {
    color: #3d4963;
  }

  .btn-underline-a.btn-white, .navbar-a .navbar-nav .nav-item .btn-white.nav-link {
    color: #fff;
  }

  .btn-underline-a.btn-white:hover, .navbar-a .navbar-nav .nav-item .btn-white.nav-link:hover {
    color: #eaeaea;
  }

  .btn-underline-a.btn-black, .navbar-a .navbar-nav .nav-item .btn-black.nav-link {
    color: #141516;
  }

  .btn-underline-b {
    background: none;
    border: none;
    padding: 0 0 .75em;
    transition: all .3s;
    position: relative;
  }

  .btn-underline-b:before {
    content: "";
    transform-origin: 100% 0;
    background-color: currentColor;
    width: 100%;
    height: 1px;
    transition: transform .3s;
    position: absolute;
    top: auto;
    bottom: .25em;
    left: 0;
    transform: scaleX(0);
  }

  .btn-underline-b.bd-thin:before {
    border-width: 1px;
  }

  .btn-underline-b.btn-lg {
    padding: 0 0 .125em;
    font-size: 1.25rem;
  }

  .btn-underline-b.btn-lg:before {
    bottom: 0;
  }

  .btn-underline-b.btn-xl {
    padding: 0 0 .25em;
  }

  .btn-underline-b.btn-xl:before {
    bottom: 0;
  }

  .btn-underline-b:focus, .btn-underline-b:active, .btn-underline-b:hover {
    opacity: .7;
    box-shadow: none;
    color: currentColor;
    background: none;
    outline: none;
  }

  .btn-underline-b:focus:before, .btn-underline-b:active:before, .btn-underline-b:hover:before {
    transform-origin: 0 0;
    transform: scaleX(1);
  }

  .btn-underline-b.btn-primary {
    color: #bb2464;
  }

  .btn-underline-b.btn-secondary {
    color: #3d4963;
  }

  .btn-underline-b.btn-white {
    color: #fff;
  }

  .btn-underline-b.btn-white:hover {
    color: #eaeaea;
  }

  .btn-underline-b.btn-black {
    color: #141516;
  }

  .btn-overline-a {
    color: currentColor;
    background: none;
    border: none;
    padding: .75em 0;
    transition: all .3s;
    position: relative;
  }

  .btn-overline-a:before, .btn-overline-a:after {
    content: "";
    border-top: 2px solid;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: .25em;
  }

  .btn-overline-a:before {
    left: 0;
    right: 100%;
  }

  .btn-overline-a:after {
    transition-delay: .2s;
    left: 0%;
    right: 0%;
  }

  .btn-overline-a.bd-thin:before, .btn-overline-a.bd-thin:after {
    border-width: 1px;
  }

  .btn-overline-a.btn-xl {
    padding: .25em 0;
  }

  .btn-overline-a.btn-xl:before, .btn-overline-a.btn-xl:after {
    bottom: 0;
  }

  .btn-overline-a:focus, .btn-overline-a:active, .btn-overline-a:hover {
    color: currentColor;
    opacity: .7;
    box-shadow: none;
    background: none;
    outline: none;
  }

  .btn-overline-a:focus:before, .btn-overline-a:active:before, .btn-overline-a:hover:before {
    transition-delay: .2s;
    right: 0%;
  }

  .btn-overline-a:focus:after, .btn-overline-a:active:after, .btn-overline-a:hover:after {
    transition-delay: 0s;
    left: 100%;
  }

  .btn-overline-a.btn-primary {
    color: #bb2464;
  }

  .btn-overline-a.btn-secondary {
    color: #3d4963;
  }

  .btn-overline-a.btn-white {
    color: #fff;
  }

  .btn-overline-a.btn-white:hover {
    color: #eaeaea;
  }

  .btn-overline-a.btn-black {
    color: #141516;
  }

  .btn-scrolldown-a {
    color: currentColor;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    text-decoration: none;
    display: flex;
  }

  .btn-scrolldown-a .icon ~ .text, .btn-scrolldown-a .text ~ .icon {
    margin-left: 1.5em;
  }

  .btn-scrolldown-a .text {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    transition: all .2s;
    display: inline-block;
  }

  @media screen and (width >= 992px) {
    .btn-scrolldown-a .text {
      font-size: 2.625rem;
    }
  }

  .btn-scrolldown-a .icon-scrolldown {
    border-radius: 2em;
    width: 5em;
    height: 2em;
    font-size: 1em;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    display: flex;
    position: relative;
    box-shadow: inset 0 0 0 1em;
  }

  .btn-scrolldown-a .icon-scrolldown:after {
    color: #fff;
    content: "";
    z-index: 1;
    border: 2px solid;
    border-top: none;
    border-left: none;
    width: 1em;
    height: 1em;
    font-size: .5em;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(calc(-50% - .2em))rotate(45deg);
  }

  .btn-scrolldown-a:hover {
    color: currentColor;
  }

  .btn-scrolldown-a:hover .text {
    opacity: 1;
  }

  .btn-scrolldown-a:hover .icon-scrolldown {
    box-shadow: inset 0 0 0 1px;
  }

  .btn-scrolldown-a:hover .icon-scrolldown:after {
    color: currentColor;
  }

  .content-white .btn-scrolldown-a .icon-scrolldown:after {
    color: #141516;
  }

  .content-white .btn-scrolldown-a:hover .icon-scrolldown:after {
    color: #fff;
  }

  .btn-scrolldown-b {
    color: currentColor;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    display: inline-flex;
  }

  .btn-scrolldown-b .icon {
    margin-bottom: 8px;
  }

  .btn-scrolldown-b .text {
    font-size: .875rem;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
  }

  .btn-scrolldown-b .text-before-vertical {
    text-align: center;
    perspective: 40px;
    opacity: .3;
    margin: 1rem 0;
    font-size: .875rem;
    display: block;
    position: relative;
  }

  .btn-scrolldown-b .text-before-vertical .char {
    width: 1em;
    height: 1em;
    transition: all cubic-bezier(.25, .75, .2, 1);
    display: block;
  }

  .btn-scrolldown-b .icon-scrolldown {
    border: 1px solid #ffffff4d;
    border-radius: 40px;
    width: 22px;
    height: 38px;
    display: block;
    position: relative;
  }

  .btn-scrolldown-b .icon-scrolldown:after {
    content: "";
    background: #fff;
    border-radius: 16px;
    width: 6px;
    height: 6px;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn-scrolldown-b:hover {
    color: currentColor;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char {
    transition: all 2s;
    transform: rotateY(360deg);
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(0) {
    transition-delay: -.075s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:first-child {
    transition-delay: 0s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(2) {
    transition-delay: 75ms;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(3) {
    transition-delay: .15s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(4) {
    transition-delay: .225s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(5) {
    transition-delay: .3s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(6) {
    transition-delay: .375s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(7) {
    transition-delay: .45s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(8) {
    transition-delay: .525s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(9) {
    transition-delay: .6s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(10) {
    transition-delay: .675s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(11) {
    transition-delay: .75s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(12) {
    transition-delay: .825s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(13) {
    transition-delay: .9s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(14) {
    transition-delay: .975s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(15) {
    transition-delay: 1.05s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(16) {
    transition-delay: 1.125s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(17) {
    transition-delay: 1.2s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(18) {
    transition-delay: 1.275s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(19) {
    transition-delay: 1.35s;
  }

  .btn-scrolldown-b:hover .text-before-vertical .char:nth-child(20) {
    transition-delay: 1.425s;
  }

  .btn-scrolldown-b:hover .icon-scrolldown:after {
    top: 22px;
  }

  .btn-scrolldown-b:hover .text {
    opacity: .5;
  }

  .btn-icon {
    color: #fff;
    background: #141516;
    border: none;
    border-radius: 100rem;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3125rem;
    display: inline-flex;
    position: relative;
  }

  .btn-icon:before {
    z-index: 0;
    content: "";
    opacity: .25;
    background: currentColor;
    border-radius: 2rem;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0;
    transform: scale(0);
  }

  .btn-icon .icon {
    z-index: 1;
    position: relative;
  }

  .btn-icon:focus, .btn-icon:hover {
    color: #fff;
    background: #bb2464;
    outline: none;
  }

  .btn-icon:focus:before, .btn-icon:hover:before {
    transform: scale(1.02);
  }

  .btn-icon.btn-white {
    color: #141516;
    background: #fff;
  }

  .btn-icon.btn-white:before {
    opacity: 1;
    background: #141516;
  }

  .btn-icon.btn-white:focus, .btn-icon.btn-white:hover {
    color: #fff;
  }

  .btn-icon-a {
    color: currentColor;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    padding: 0;
    font-size: 1.5rem;
    line-height: 1;
    transition: all .3s;
    display: inline-flex;
  }

  .btn-icon-a:focus, .btn-icon-a:hover {
    color: currentColor;
    opacity: .5;
  }

  .btn-fab {
    color: currentColor;
    background: none;
    border: none;
    border-radius: 100rem;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.3125rem;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    display: flex;
    position: relative;
  }

  .btn-fab:before {
    z-index: 0;
    content: "";
    opacity: .5;
    background: none;
    border: 2px solid;
    border-radius: 2rem;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0;
  }

  .btn-fab .icon {
    z-index: 1;
    line-height: 1;
    position: relative;
  }

  .btn-fab:focus, .btn-fab:hover {
    background: none;
    outline: none;
    transform: scale(1.4);
  }

  .btn-fab:focus:before, .btn-fab:hover:before {
    opacity: 0;
    transform: scale(.5);
  }

  .btn-fab.btn-white {
    color: #bb2464;
    background: #fff;
  }

  .btn-fab.btn-white:before {
    opacity: 1;
    background: #bb2464;
  }

  .btn-fab.btn-white:focus, .btn-fab.btn-white:hover {
    color: #fff;
  }

  .btn-xl {
    font-size: 2rem;
    line-height: 1;
  }

  @media screen and (width >= 768px) {
    .btn-xl {
      font-size: 3rem;
    }
  }

  .card-pricing-a {
    background: #ffffff1a;
    border: 2px solid #fff;
    padding: 0;
  }

  .card-pricing-a .card-header {
    background: none;
    border: none;
    padding: 1.25rem;
  }

  .card-pricing-a .card-header .title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .card-pricing-a .card-footer {
    background: none;
    border: none;
    padding: 1.25rem;
  }

  .card-pricing-a .card-body {
    padding: 1.25rem;
  }

  .card-pricing-a .card-header ~ .card-body, .card-pricing-a .card-body ~ .card-footer {
    padding-top: 0;
  }

  .card-pricing-a .price {
    line-height: 1.2;
  }

  .card-pricing-a .price .amount {
    font-size: 3rem;
    font-weight: bold;
  }

  .card-pricing-a .price p {
    margin-bottom: 0;
    font-size: 1.25rem;
    display: inline-block;
  }

  .card-pricing-a .list-feature {
    margin: 1.25rem 0 0;
    padding-left: 0;
    font-size: 1.25rem;
    line-height: 1.75;
    list-style: none;
  }

  .card-pricing-a.featured {
    color: #bb2464;
    background: #fff;
  }

  .card-pricing-a.featured .list-feature {
    color: #141516;
  }

  .card-pricing-a.featured .list-feature .icon {
    color: #bb2464;
  }

  .article-a h2, .article-a .title {
    margin-bottom: .5em;
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 500;
    line-height: 1.2;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .article-a h2, .article-a .title {
      max-width: 50%;
      font-size: 4rem;
    }
  }

  .article-a h3 {
    margin-bottom: .25em;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .article-a .subtitle {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @media screen and (width >= 768px) {
    .article-a .subtitle {
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }

  @media screen and (width >= 992px) {
    .article-a .subtitle {
      max-width: 50%;
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  .article-a p {
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .article-a p {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .article-a p {
      font-size: 1.25rem;
      line-height: 1.7;
    }

    .article-a > p {
      max-width: 40rem;
    }
  }

  .article-a hr {
    opacity: .1;
    border: none;
    border-bottom: 2px solid;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .dropdown-toggle-a {
    position: relative;
    padding-right: 1.5em !important;
  }

  .dropdown-toggle-a .icon.icon-dropdown {
    height: 1em;
    display: inline-block;
  }

  .dropdown-toggle-a .icon.icon-dropdown:after, .dropdown-toggle-a .icon.icon-dropdown:before {
    content: "";
    border: none;
    border-radius: 1em;
    width: 1.25em;
    height: 1.25em;
    line-height: 1em;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: 0;
    right: 0;
  }

  .dropdown-toggle-a .icon.icon-dropdown:before {
    box-shadow: inset 0 0 0 1px #fff3;
  }

  .dropdown-toggle-a .icon.icon-dropdown:after {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228.628%22%20height%3D%225.137%22%20viewBox%3D%220%200%208.628%205.137%22%3E%0D%0A%20%20%3Cpath%20id%3D%22chevron-down-sharp%22%20d%3D%22M9.193,12.87l3.491-3.491.823.823L9.193,14.516,4.879,10.2,5.7,9.379Z%22%20transform%3D%22translate(-4.879%20-9.379)%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
    background-position: 50% 55%;
    background-repeat: no-repeat;
    background-size: .5em;
  }

  .dropdown-toggle-a:hover .icon-dropdown:before {
    box-shadow: inset 0 0 0 .625em #fff;
  }

  .dropdown-toggle-a:hover .icon-dropdown:after {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228.628%22%20height%3D%225.137%22%20viewBox%3D%220%200%208.628%205.137%22%3E%0D%0A%20%20%3Cpath%20id%3D%22chevron-down-sharp%22%20d%3D%22M9.193,12.87l3.491-3.491.823.823L9.193,14.516,4.879,10.2,5.7,9.379Z%22%20transform%3D%22translate(-4.879%20-9.379)%22%20fill%3D%22%23272123%22%20fill-rule%3D%22evenodd%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
    background-position: 50% 55%;
    background-repeat: no-repeat;
    background-size: .5em;
  }

  .modal-a {
    backdrop-filter: blur(8px);
    visibility: hidden;
    opacity: 0;
    color: #141516;
    background-color: #ffffff80;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    display: flex !important;
  }

  .modal-a .modal-content {
    background: none;
    border: none;
    padding: 0;
  }

  .modal-a .modal-dialog {
    opacity: 0;
    width: 100%;
    max-width: 360px;
    height: fit-content;
    transition: all .3s;
    transform: scale(1.1);
  }

  .modal-a .modal-dialog .popup-header {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
  }

  .modal-a .modal-dialog .popup-header .title {
    font-size: 2rem;
    font-weight: bold;
  }

  .modal-a .modal-dialog .popup-body {
    background-color: #ffffffb3;
    border: 8px solid #000;
    border-radius: 0;
    padding: 3rem;
  }

  .modal-a.show {
    visibility: visible;
    opacity: 1;
  }

  .modal-a.show .modal-dialog {
    opacity: 1;
    transition: all .3s;
    transform: none;
  }

  .modal-backdrop.show {
    background-color: #0000;
  }

  .item-feature-a a {
    color: currentColor;
    text-decoration: none;
  }

  .item-feature-a .img {
    margin-bottom: 1rem;
  }

  .item-feature-a .img .icon {
    margin-bottom: 0;
    font-size: 4rem;
    line-height: 1;
  }

  @media screen and (width >= 992px) {
    .item-feature-a .item-body {
      max-width: 24rem;
    }
  }

  .item-feature-a .item-body h4 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .item-feature-a .item-body .f-title {
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    position: relative;
  }

  .item-feature-a .item-body .f-title:after {
    content: "";
    background-color: currentColor;
    width: 2rem;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  .item-feature-a .item-body p {
    font-size: .875rem;
    line-height: 1.25;
  }

  @media screen and (width >= 768px) {
    .item-feature-a .item-body p {
      font-size: .875rem;
      line-height: 1.25;
    }
  }

  @media screen and (width >= 992px) {
    .item-feature-a .item-body p {
      font-size: .875rem;
      line-height: 1.25;
    }
  }

  .item-feature-a .item-body p:last-child {
    margin-bottom: 0;
  }

  .item-feature-b {
    align-items: center;
    display: flex;
  }

  .item-feature-b a {
    color: currentColor;
    text-decoration: none;
  }

  .item-feature-b .img {
    border: 2px solid #fff;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    display: flex;
  }

  .item-feature-b .img .icon {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1;
  }

  .item-feature-b .item-body h4 {
    margin-bottom: .5rem;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.1;
  }

  .item-feature-b .item-body p {
    font-size: 1rem;
    line-height: 1.2;
  }

  @media screen and (width >= 768px) {
    .item-feature-b .item-body p {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-feature-b .item-body p {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .item-feature-b .item-body p:last-child {
    margin-bottom: 0;
  }

  .item-feature-c {
    display: block;
    position: relative;
  }

  .item-feature-c a {
    color: currentColor;
    text-decoration: none;
  }

  .item-feature-c .item-img {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  .item-feature-c .item-img img {
    width: 100%;
    height: 100%;
    transition: all .3s;
  }

  .item-feature-c .item-img img.fit {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-feature-c .item-img.anim-hover {
    opacity: 0;
    visibility: hidden;
    --marg: -2rem;
    top: var(--marg, 0);
    left: var(--marg, 0);
    right: var(--marg, 0);
    bottom: var(--marg, 0);
    transition: all .3s;
    transform: scale(.9);
  }

  .item-feature-c .icon {
    opacity: .3;
    z-index: 1;
    margin-bottom: .5rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    display: block;
    position: relative;
  }

  .item-feature-c .item-body {
    z-index: 1;
    display: block;
    position: relative;
  }

  .item-feature-c .item-body h4 {
    margin-bottom: 1rem;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1;
  }

  .item-feature-c .item-body p {
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .item-feature-c .item-body p {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-feature-c .item-body p {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .item-feature-c .item-body p:last-child {
    margin-bottom: 0;
  }

  .item-feature-c:hover .item-img.anim-hover, .item-feature-c:focus .item-img.anim-hover {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .item-feature-c:hover .item-img img, .item-feature-c:focus .item-img img {
    transition: all 5s linear;
    transform: scale(1.2);
  }

  a.item-feature-c {
    color: currentColor;
    text-decoration: none;
  }

  .item-image-a {
    color: currentColor;
    padding-bottom: 12rem;
    text-decoration: none;
    display: block;
    position: relative;
  }

  .item-image-a img {
    z-index: 0;
    width: 100%;
    transition: all .3s;
    position: relative;
  }

  .item-image-a img.fit {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-image-a .item-img {
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    overflow: hidden;
  }

  .item-image-a .item-img:before {
    content: "";
    z-index: 1;
    background: #8b8b8b;
    height: 0%;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: auto 0 0;
  }

  .item-image-a .item-header {
    z-index: 2;
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
  }

  @media screen and (width >= 992px) {
    .item-image-a .item-header {
      top: 3rem;
      left: 3rem;
      right: 3rem;
    }
  }

  .item-image-a .item-header .number {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1;
  }

  .item-image-a .item-body {
    z-index: 2;
    margin-top: 1.75rem;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    top: calc(100% - 16rem);
    left: 2rem;
    right: 2rem;
  }

  @media screen and (width >= 992px) {
    .item-image-a .item-body {
      left: 3rem;
      right: 3rem;
    }
  }

  .item-image-a .item-body h4 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
  }

  @media screen and (width >= 992px) {
    .item-image-a .item-body h4 {
      font-size: 4.5rem;
    }
  }

  .item-image-a .item-body p {
    opacity: 1;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.2;
  }

  .item-image-a .item-body .price {
    font-size: 1.3125rem;
    line-height: 1.2;
  }

  .item-image-a .item-hover-icon {
    z-index: 1;
    opacity: 0;
    transition: all .3s;
    position: absolute;
    top: calc(50% - 2rem);
    left: 50%;
    transform: translateX(-50%)translateY(-50%)scale(.8);
  }

  .item-image-a .item-hover-icon .icon {
    background: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    font-size: 3rem;
    display: inline-flex;
  }

  .item-image-a.anim-scroll .item-img:before {
    height: 100%;
  }

  .item-image-a.anim-scroll.elem-visible .item-img:before {
    height: 0%;
  }

  a.item-image-a {
    perspective: 800px;
  }

  a.item-image-a:hover {
    color: currentColor;
  }

  a.item-image-a:hover .item-hover-icon {
    opacity: 1;
    transform: translateX(-50%)translateY(-50%);
  }

  .item-image-b {
    color: currentColor;
    text-decoration: none;
    display: block;
    position: relative;
  }

  .item-image-b:before {
    content: "";
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    transition: all .3s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0;
    box-shadow: 0 0 0 2px #80808033;
  }

  .item-image-b img {
    width: 100%;
    transition: all .3s;
  }

  .item-image-b img.fit {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-image-b .item-img {
    transition: all .3s;
    position: relative;
    overflow: hidden;
  }

  .item-image-b .item-body {
    margin-top: 1.75rem;
  }

  .item-image-b .item-body h4 {
    color: currentColor;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .item-image-b .item-body p {
    opacity: 1;
    letter-spacing: -.015em;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .item-image-b .item-body p {
      font-size: 1rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-image-b .item-body p {
      font-size: 1rem;
      line-height: 1.7;
    }
  }

  .item-image-b .item-body .price {
    color: currentColor;
    font-size: 2rem;
    line-height: 1.2;
  }

  .item-image-b .item-body hr {
    opacity: .3;
    border: none;
    border-bottom: 1px solid;
    margin: .75rem 0;
  }

  .item-image-b .item-hover-icon {
    z-index: 1;
    opacity: 0;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%)scale(.8);
  }

  .item-image-b .item-hover-icon .icon {
    background: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    font-size: 3rem;
    display: inline-flex;
  }

  a.item-image-b:hover {
    color: currentColor;
  }

  a.item-image-b:hover:before {
    opacity: 1;
    visibility: visible;
    inset: -1rem;
  }

  a.item-image-b:hover img:not(.no-zoom) {
    transition: all 5s linear;
    transform: scale(1.2);
  }

  a.item-image-b:hover .item-hover-icon {
    opacity: 1;
    transform: translateX(-50%)translateY(-50%);
  }

  .item-image-c {
    text-decoration: none;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .item-image-c img {
    width: 100%;
    transition: all .3s;
  }

  .item-image-c img.fit {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-image-c .item-header {
    padding: 32px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .item-image-c .item-header h4 {
    text-shadow: 0 1px 2px #0003;
    font-size: 3rem;
    font-weight: bold;
  }

  .item-image-c .item-header h5 {
    text-shadow: 0 1px 2px #0003;
    font-size: 2rem;
    font-weight: bold;
  }

  .item-image-c .item-header p {
    color: #ffffffbf;
    font-size: 1.25rem;
  }

  .item-image-c.h-thumb img {
    height: 18rem;
  }

  a.item-image-c {
    color: currentColor;
    text-decoration: none;
  }

  a.item-image-c:hover {
    color: currentColor;
  }

  a.item-image-c:hover img {
    transition: all 5s linear;
    transform: scale(1.2);
  }

  .item-image-d {
    align-items: center;
    display: grid;
  }

  .item-image-d .item-layer {
    grid-area: 1 / 1;
    width: 100%;
  }

  .item-image-d .item-img {
    overflow: hidden;
  }

  .item-image-d .item-img.h-vh {
    max-height: 100vh;
  }

  .item-image-d .item-img img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  .item-image-d .item-body {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .item-image-d .item-body h4 {
    text-shadow: 0 1px 2px #0003;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .item-image-d .item-body p {
    color: #fff;
    font-size: 1.25rem;
  }

  .item-image-d .item-body .text-big {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 1.2;
  }

  @media screen and (width >= 768px) {
    .item-image-d .item-body .text-big {
      font-size: 5rem;
    }
  }

  @media screen and (width >= 992px) {
    .item-image-d .item-body .text-big {
      font-size: 8rem;
    }
  }

  .item-image-e {
    text-align: center;
    display: block;
  }

  .item-image-e .img {
    vertical-align: middle;
    width: 100%;
    max-width: 240px;
    height: 100%;
    line-height: 1;
    display: inline-flex;
    position: relative;
    overflow: hidden;
  }

  .item-image-e .img.img-full {
    max-width: unset;
  }

  .item-image-e .img:after {
    content: "";
    border-radius: inherit;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
    position: absolute;
    inset: 0;
    box-shadow: inset 0 0 0 2px #fff;
  }

  .item-image-e img {
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
    width: 100%;
    transition: all 3s linear;
    position: relative;
  }

  .item-image-e .item-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .item-image-e .item-body a.page-link {
    color: currentColor;
    background: none;
    border: none;
    margin-bottom: 1rem;
    padding: 0;
  }

  .item-image-e .item-body h4 {
    margin-bottom: .5rem;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.1;
  }

  .item-image-e .item-body p {
    font-size: 1rem;
    line-height: 1.2;
  }

  @media screen and (width >= 768px) {
    .item-image-e .item-body p {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-image-e .item-body p {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .item-image-e .item-body p:last-child {
    margin-bottom: 0;
  }

  .item-image-e .nav-social {
    display: inline-flex;
  }

  .item-image-e .img:hover:after {
    box-shadow: inset 0 0 0 8px #fff;
  }

  .item-image-e .img:hover img {
    transform: scale(1.3);
  }

  .item-image-e.no-border .img:after {
    box-shadow: none;
  }

  .item-image-f {
    color: currentColor;
    text-decoration: none;
    display: block;
    position: relative;
  }

  .item-image-f img {
    width: 100%;
    transition: all .3s;
  }

  .item-image-f img.fit {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-image-f .item-img {
    transition: all .3s;
    position: relative;
    overflow: hidden;
  }

  .item-image-f .item-header {
    z-index: 1;
    width: 100%;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .item-image-f .item-body {
    z-index: 1;
    margin-top: 1.75rem;
    padding: 4rem 2rem 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .item-image-f .item-body:before {
    content: "";
    z-index: -1;
    opacity: .4;
    background: linear-gradient(#0000, #000);
    transition: all .3s;
    position: absolute;
    inset: 0;
  }

  .item-image-f .item-body .title, .item-image-f .item-body h4 {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .item-image-f .item-body p, .item-image-f .item-body .price {
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .item-image-f .item-body hr {
    opacity: .3;
    border: none;
    border-bottom: 2px solid;
    margin: .875rem 0;
    transition: all .3s;
  }

  .item-image-f .item-body.item-body-glass {
    backdrop-filter: blur(5px);
    background-color: #5a5a5a33;
    padding: 1rem;
    inset: auto 1rem 1rem;
  }

  .item-image-f .item-body.item-body-glass:before {
    display: none;
  }

  a.item-image-f:hover {
    color: currentColor;
  }

  a.item-image-f:hover:before {
    opacity: 1;
    visibility: visible;
    inset: -1rem;
  }

  a.item-image-f:hover .item-body:before {
    opacity: .6;
  }

  a.item-image-f:hover img:not(.no-zoom) {
    transition: all 5s linear;
    transform: scale(1.2);
  }

  .item-pricing-a {
    background: #ffffff1a;
    border: 2px solid #fff;
    padding: 0;
  }

  .item-pricing-a .item-header {
    background: none;
    border: none;
    padding: 1.25rem;
  }

  .item-pricing-a .item-header .title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .item-pricing-a .item-footer {
    background: none;
    border: none;
    padding: 1.25rem;
  }

  .item-pricing-a .item-body {
    padding: 1.25rem;
  }

  .item-pricing-a .item-header ~ .item-body, .item-pricing-a .item-body ~ .item-footer {
    padding-top: 0;
  }

  .item-pricing-a .price {
    line-height: 1.2;
  }

  .item-pricing-a .price .amount {
    font-size: 3rem;
    font-weight: bold;
  }

  .item-pricing-a .price .unit {
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .item-pricing-a .price .unit {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-pricing-a .price .unit {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .item-pricing-a .price p {
    margin-bottom: 0;
    font-size: 1.25rem;
    display: inline-block;
  }

  .item-pricing-a .list-feature {
    margin: 1.25rem 0 0;
    padding-left: 0;
    font-size: 1rem;
    line-height: 1.7;
    list-style: none;
  }

  @media screen and (width >= 768px) {
    .item-pricing-a .list-feature {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .item-pricing-a .list-feature {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .item-pricing-a.featured {
    color: #bb2464;
    background: #fff;
  }

  .item-pricing-a.featured .list-feature {
    color: #141516;
  }

  .item-pricing-a.featured .list-feature .icon {
    color: #bb2464;
  }

  .item-quote-a .quote {
    color: currentColor;
    font-size: 1.5rem;
    line-height: 1.5;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .item-quote-a .quote {
      font-size: 3rem;
      line-height: 1.5;
    }
  }

  .item-quote-a .quote .quote-icon {
    vertical-align: super;
    opacity: .3;
    height: 1em;
    margin-right: .25em;
    font-size: 1.75em;
    line-height: 0;
    display: inline-block;
    transform: translateY(.05em);
  }

  .item-quote-a .quote .quote-icon svg {
    width: 1em;
    height: 1em;
  }

  @media screen and (width >= 992px) {
    .item-quote-a .quote .quote-icon {
      font-size: 1em;
      transform: translateY(.2em);
    }
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 24px;
    padding-right: var(--margin-x-sm);
    padding-left: 24px;
    padding-left: var(--margin-x-sm);
  }

  @media screen and (width >= 768px) {
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      padding-right: 32px;
      padding-right: var(--margin-x-md);
      padding-left: 32px;
      padding-left: var(--margin-x-md);
    }
  }

  @media screen and (width >= 992px) {
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .row.shifted-odd {
    --shift-width: 8rem;
    margin-bottom: var(--shift-width);
  }

  @media screen and (width >= 992px) {
    .row.shifted-odd .col:nth-child(odd) {
      top: var(--shift-width);
      position: relative;
    }
  }

  .img-square {
    aspect-ratio: 1;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  .img-4x3 {
    aspect-ratio: 4 / 3;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  .img-3x4 {
    aspect-ratio: 3 / 4;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  .img-200 {
    width: 200px;
  }

  .figure-a {
    --figure-a-m-x: 3rem;
    --figure-a-m-y: 3rem;
    padding-left: var(--figure-a-m-x);
    padding-bottom: var(--figure-a-m-y);
    margin: 0;
    position: relative;
  }

  @media screen and (width >= 768px) {
    .figure-a {
      --figure-a-m-x: 2rem;
      --figure-a-m-y: 3rem;
    }
  }

  @media screen and (width >= 992px) {
    .figure-a {
      --figure-a-m-x: 4rem;
      --figure-a-m-y: 4rem;
    }
  }

  .figure-a .img-anim {
    display: inline;
  }

  .figure-a .img-1 {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .figure-a .img-1 img {
    width: 100%;
    height: auto;
  }

  @media screen and (width >= 992px) {
    .figure-a .img-1 img {
      width: auto;
      height: 100%;
      position: absolute;
      right: 0;
    }
  }

  .figure-a .img-2 {
    width: 65%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media screen and (width >= 992px) {
    .figure-a .img-2 {
      width: 45%;
    }
  }

  .figure-a img {
    width: 100%;
  }

  .figure-b {
    --figure-a-m-x: 3rem;
    --figure-a-m-y: 3rem;
    margin: 0;
    padding: 0;
    position: relative;
  }

  @media screen and (width >= 768px) {
    .figure-b {
      --figure-a-m-x: 2rem;
      --figure-a-m-y: 3rem;
    }
  }

  @media screen and (width >= 992px) {
    .figure-b {
      --figure-a-m-x: 4rem;
      --figure-a-m-y: 4rem;
    }
  }

  .figure-b .img-1 {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .figure-b .img-1 img {
      width: auto;
      height: 100%;
      position: absolute;
      right: 0;
    }
  }

  .figure-b img {
    width: 100%;
  }

  @media screen and (width >= 992px) {
    .row-text-lg-fig {
      position: relative;
    }

    .row-text-lg-fig .col-fig {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .slide-item-a {
    width: 100%;
  }

  .slide-item-a .item-content.with-margin {
    padding-left: var(--margin-x);
  }

  .slide-item-a .item-header .number {
    margin-bottom: .25em;
    font-size: 5.5rem;
    font-weight: bold;
    line-height: .8;
  }

  .slide-item-a img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 400px;
  }

  @media screen and (width >= 992px) {
    .slide-item-a img {
      height: auto;
    }
  }

  .slide-item-b {
    padding: 0 var(--margin-x-sm);
    width: 100%;
    max-width: 48rem;
  }

  @media screen and (width >= 768px) {
    .slide-item-b {
      padding: 0 var(--margin-x-md);
    }
  }

  @media screen and (width >= 992px) {
    .slide-item-b {
      padding: 0;
    }
  }

  .slide-item-b .item-wrapper {
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    align-items: center;
    display: grid;
  }

  .slide-item-b .item-image {
    grid-area: 1 / 1;
    overflow: hidden;
  }

  .slide-item-b .item-content {
    grid-area: 1 / 1;
    padding: 16px;
  }

  @media screen and (width >= 992px) {
    .slide-item-b .item-content {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .slide-item-b .item-content .subtitle {
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: 1rem;
  }

  @media screen and (width >= 992px) {
    .slide-item-b .item-content .subtitle {
      font-size: 1rem;
    }
  }

  .slide-item-b .item-content .title {
    text-shadow: 0 1px 2px #0003;
    margin-bottom: .1em;
    font-size: 3rem;
    font-weight: bold;
  }

  @media screen and (width >= 992px) {
    .slide-item-b .item-content .title {
      margin-bottom: .5rem;
      font-size: 4rem;
    }
  }

  .slide-item-b .item-content .category {
    font-size: 1rem;
  }

  @media screen and (width >= 992px) {
    .slide-item-b .item-content .category {
      font-size: 1.5rem;
    }
  }

  .slide-item-b img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 400px;
  }

  @media screen and (width >= 992px) {
    .slide-item-b img {
      height: auto;
    }
  }

  .slide-item-gallery-a {
    width: 100vw;
    height: 100vh;
    padding: 0;
  }

  .slide-item-gallery-a .item-wrapper {
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    align-items: center;
    width: 100%;
    height: 100%;
    display: grid;
  }

  .slide-item-gallery-a .item-image {
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slide-item-gallery-a .item-content {
    padding: 4rem var(--margin-x);
    grid-area: 1 / 1;
    align-self: flex-end;
  }

  @media screen and (width >= 768px) {
    .slide-item-gallery-a .item-content {
      padding: 4rem var(--margin-x);
    }
  }

  @media screen and (width >= 992px) {
    .slide-item-gallery-a .item-content {
      padding: 8rem var(--margin-x);
    }
  }

  .slide-item-gallery-a .item-content .subtitle, .slide-item-gallery-a .item-content .title, .slide-item-gallery-a .item-content .category {
    text-shadow: 0 1px 2px #0003;
  }

  .slide-item-gallery-a img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .swiper-container-fade .swiper-slide {
    opacity: 0 !important;
  }

  .swiper-container-fade .swiper-slide.swiper-slide-active {
    opacity: 1 !important;
  }

  .slider-simple-a {
    position: relative;
  }

  .slider-simple-a .slider-btns {
    margin-left: 170px;

    @media screen and (width >= 1200px) {
      & {
        margin-left: 134px;
      }
    }

    @media screen and (width >= 1400px) {
      & {
        margin-left: 96px;
      }
    }
  }

  .slider-simple-a .slider-btns .btns {
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 2rem;
    display: flex;
  }

  .slider-simple-a .slider-btn {
    color: currentColor;
    border: none;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: 0;
    font-size: 4rem;
    transition: all .3s;
    display: inline-flex;
    position: relative;
    top: 0;
    left: auto;
    right: auto;
  }

  .slider-simple-a .slider-btn:after, .slider-simple-a .slider-btn a:before {
    display: none;
  }

  .slider-simple-a .slider-btn .icon {
    font-size: inherit;
  }

  .slider-simple-a .swiper-button-prev:hover, .slider-simple-a .slider-prev:hover, .slider-simple-a .swiper-button-next:hover, .slider-simple-a .slider-next:hover {
    opacity: .25;
  }

  .slider-simple-a .swiper-pagination {
    right: var(--margin-x);
    width: auto;
    position: absolute;
    bottom: 4rem;
    left: auto;
  }

  .slider-simple-a .swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    transition: all .2s;
    transition-timing-function: var(--transition-curve-1);
    background: currentColor;
    width: 16px;
    height: 16px;
    margin: 0 8px;
  }

  .slider-simple-a .swiper-pagination .swiper-pagination-bullet-active {
    opacity: 1;
    color: #fbe54e;
    transform: scale(2);
  }

  .slider-simple-a .slide-item-a .item-content {
    opacity: 0;
    transition: all .9s .75s;
    transition-timing-function: var(--transition-curve-2);
    transform: translateX(2rem);
  }

  .slider-simple-a .slide-item-a .item-header {
    transition: all .9s .75s;
    transition-timing-function: var(--transition-curve-2);
    transform: translateX(4rem);
  }

  .slider-simple-a .slide-item-a .item-image {
    transition: all .6s .3s;
    transition-timing-function: var(--transition-curve-1);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  }

  .slider-simple-a.animated .slide-item-a.swiper-slide-active .item-image {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: none;
  }

  .slider-simple-a.animated .slide-item-a.swiper-slide-active .item-header, .slider-simple-a.animated .slide-item-a.swiper-slide-active .item-content {
    opacity: 1;
    transform: none;
  }

  .slider-simple-b {
    position: relative;
  }

  .slider-simple-b .slider-btn {
    color: #fff;
    border: none;
    border-radius: 10rem;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    margin: 0;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .slider-simple-b .slider-btn:before, .slider-simple-b .slider-btn:after {
    content: "";
    border-radius: inherit;
    opacity: .25;
    border: 2px solid #fff;
    width: 100%;
    height: 100%;
    transition: all .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
  }

  .slider-simple-b .slider-btn .icon {
    font-size: 2rem;
  }

  .slider-simple-b .swiper-button-prev, .slider-simple-b .slider-prev {
    left: calc(50% - 24rem - 128px);
    right: auto;
  }

  .slider-simple-b .swiper-button-prev:after, .slider-simple-b .slider-prev:after {
    border-top-color: #0000;
    border-left-color: #0000;
  }

  .slider-simple-b .swiper-button-prev:before, .slider-simple-b .slider-prev:before {
    opacity: 0;
    border-top-color: #0000;
    border-left-color: #0000;
  }

  .slider-simple-b .swiper-button-prev:hover:after, .slider-simple-b .slider-prev:hover:after {
    opacity: 1;
  }

  .slider-simple-b .swiper-button-prev:hover:before, .slider-simple-b .slider-prev:hover:before {
    opacity: 1;
    transform: rotate(135deg);
  }

  .slider-simple-b .swiper-button-next, .slider-simple-b .slider-next {
    left: auto;
    right: calc(50% - 24rem - 128px);
  }

  .slider-simple-b .swiper-button-next:after, .slider-simple-b .slider-next:after {
    border-bottom-color: #0000;
    border-right-color: #0000;
  }

  .slider-simple-b .swiper-button-next:before, .slider-simple-b .slider-next:before {
    opacity: 0;
    border-bottom-color: #0000;
    border-right-color: #0000;
  }

  .slider-simple-b .swiper-button-next:hover:after, .slider-simple-b .slider-next:hover:after {
    opacity: 1;
  }

  .slider-simple-b .swiper-button-next:hover:before, .slider-simple-b .slider-next:hover:before {
    opacity: 1;
    transform: rotate(-225deg);
  }

  .slider-simple-b .swiper-pagination {
    text-align: right;
  }

  .slider-simple-b .swiper-pagination .swiper-pagination-bullet {
    opacity: .25;
    background: currentColor;
    width: 16px;
    height: 16px;
    margin: 0 8px;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
  }

  .slider-simple-b .swiper-pagination .swiper-pagination-bullet-active {
    opacity: 1;
    transform: scale(2);
  }

  .slider-simple-b.animated .slide-item-b .item-image {
    z-index: -1;
    transition: all .6s;
    transform: scale(.8);
  }

  .slider-simple-b.animated .slide-item-b .item-content {
    opacity: 0;
    z-index: 1;
    transition: all .9s;
    transform: translateX(80px);
  }

  .slider-simple-b.animated .slide-item-b.swiper-slide-active .item-image, .slider-simple-b.animated .slide-item-b.swiper-slide-active .item-content {
    opacity: 1;
    transform: none;
  }

  .slider-gallery-a {
    position: relative;
  }

  .slider-gallery-a .slider-btns {
    top: auto;
    bottom: var(--margin-y);
    right: var(--margin-x);
    margin: auto;
    padding: 0 16px;
    display: flex;
    position: absolute;
    left: auto;
  }

  .slider-gallery-a .slider-btn {
    color: currentColor;
    border: none;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: 0;
    font-size: 4rem;
    transition: all .3s;
    display: inline-flex;
    position: relative;
    top: 0;
    left: auto;
    right: auto;
  }

  .slider-gallery-a .slider-btn:after, .slider-gallery-a .slider-btn a:before {
    display: none;
  }

  .slider-gallery-a .slider-btn .icon {
    font-size: inherit;
  }

  .slider-gallery-a .swiper-button-prev:hover, .slider-gallery-a .slider-prev:hover, .slider-gallery-a .swiper-button-next:hover, .slider-gallery-a .slider-next:hover {
    opacity: .25;
  }

  .slider-gallery-a .swiper-pagination {
    bottom: var(--margin-y);
    left: var(--margin-x);
    width: fit-content;
  }

  .slider-gallery-a .swiper-pagination .swiper-pagination-bullet {
    opacity: .25;
    background: #fff;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
  }

  .slider-gallery-a .swiper-pagination .swiper-pagination-bullet-active {
    opacity: 1;
    transform: scale(2);
  }

  .slider-gallery-a.animated .slide-item-gallery-a .item-image {
    z-index: -1;
    overflow: hidden;
  }

  .slider-gallery-a.animated .slide-item-gallery-a .item-image img {
    opacity: 0;
    transition: all .6s;
    transform: scale(1.2);
  }

  .slider-gallery-a.animated .slide-item-gallery-a .item-content {
    perspective: initial;
    z-index: 1;
  }

  .slider-gallery-a.animated .slide-item-gallery-a .item-content > * {
    opacity: 0;
    transition: all .6s;
    transform: translateY(20px);
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-image {
    opacity: 1;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-image img, .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content, .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > * {
    opacity: 1;
    transform: none;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(0) {
    transition-delay: -.2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :first-child {
    transition-delay: 0s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(2) {
    transition-delay: .2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(3) {
    transition-delay: .4s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(4) {
    transition-delay: .6s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(5) {
    transition-delay: .8s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(6) {
    transition-delay: 1s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(7) {
    transition-delay: 1.2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(8) {
    transition-delay: 1.4s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(9) {
    transition-delay: 1.6s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(10) {
    transition-delay: 1.8s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(11) {
    transition-delay: 2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(12) {
    transition-delay: 2.2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(13) {
    transition-delay: 2.4s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(14) {
    transition-delay: 2.6s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(15) {
    transition-delay: 2.8s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(16) {
    transition-delay: 3s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(17) {
    transition-delay: 3.2s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(18) {
    transition-delay: 3.4s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(19) {
    transition-delay: 3.6s;
  }

  .slider-gallery-a.animated .slide-item-gallery-a.swiper-slide-active .item-content > :nth-child(20) {
    transition-delay: 3.8s;
  }

  .scroll-simple-a {
    position: relative;
  }

  .scroll-simple-a .scroll-wrapper {
    position: sticky;
    top: 128px;
    overflow-x: hidden;
  }

  .scroll-simple-a .scroll-slides {
    will-change: transform;
    align-items: center;
    display: flex;
    position: relative;
  }

  .scroll-simple-a .scroll-slides .scroll-slide {
    min-width: 100vw;
  }

  .scroll-simple-a .scroll-slides .scroll-slide:first-child {
    margin-left: 0;
  }

  .scroll-simple-a .scroll-slides .scroll-slide:last-child {
    margin-right: 0;
  }

  @media screen and (width >= 992px) {
    .scroll-simple-a .scroll-slides .scroll-slide {
      min-width: 48rem;
      height: auto;
      margin: 0 64px;
    }
  }

  .scroll-simple-a .scale-1, .scroll-simple-a .scale-2, .scroll-simple-a .speed-1, .scroll-simple-a .speed-2, .scroll-simple-a .speed-3 {
    will-change: transform;
  }

  .scroll-container {
    width: 100%;
    position: relative;
  }

  .inline-separator {
    content: "";
    vertical-align: middle;
    width: 1em;
    height: 1em;
    display: inline-flex;
    position: relative;
  }

  .inline-separator:before {
    content: "";
    border-bottom: 2px solid;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
  }

  .section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .section.with-overflow {
    overflow: initial;
  }

  .section.section-image-only {
    min-height: 14rem;
  }

  @media screen and (width >= 992px) {
    .section.section-image-only {
      min-height: 32rem;
    }
  }

  .section .img {
    width: 100%;
  }

  .section-cover, .section-cover .bg-layer {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    inset: 0;
  }

  .section-cover .bg-part {
    min-height: 100%;
  }

  .section-cover .bg-wrapper {
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex !important;
  }

  .section-cover .row {
    width: 100%;
    margin: 0;
  }

  .section-cover img, .section-cover .fill-width {
    width: 100%;
  }

  .section-cover .fit {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .section-cover .img-full, .section-cover .img-cover, .section-cover .bg-cover-img {
    position: absolute;
    inset: 0;
  }

  .section-cover .img-box {
    width: calc(100% - var(--margin-x-sm) * 2);
    height: 70vh;
    inset: auto;
  }

  @media screen and (width >= 992px) {
    .section-cover .img-box {
      width: 40vw;
      height: 60vh;
    }

    .section-cover .img-box.box-md {
      width: 36rem;
      height: 30rem;
    }
  }

  @media screen and (width >= 768px) {
    .section-cover .img-md-box {
      width: 40vw;
      height: 60vh;
      inset: auto;
    }
  }

  @media screen and (width >= 992px) {
    .section-cover .img-lg-box {
      width: 50vw;
      height: 66vh;
      inset: auto;
    }
  }

  .section-title {
    text-align: center;
    z-index: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .section-title .text {
    z-index: -1;
    margin: 0;
    font-size: 3rem;
    font-weight: 800;
    line-height: .8;
  }

  @media screen and (width >= 992px) {
    .section-title .text {
      font-size: 3rem;
    }
  }

  .section-title.z-top {
    pointer-events: none;
    z-index: 10;
  }

  .section-title.title-top {
    left: auto;
    top: var(--section-margin-y);
    right: var(--margin-x);
    width: auto;
    height: auto;
    transform: none;
  }

  @media screen and (width >= 992px) {
    .section-title.title-top {
      right: var(--margin-x);
    }
  }

  .section-title.title-right {
    width: auto;
    height: auto;
    left: auto;
    right: 0;
  }

  @media screen and (width >= 992px) {
    .section-title.title-right {
      right: calc(50vw - 33rem);
    }
  }

  .section-title.title-left {
    width: auto;
    height: auto;
    left: 0;
    right: auto;
  }

  @media screen and (width >= 992px) {
    .section-title.title-left {
      left: calc(50vw - 33rem);
    }
  }

  .section-title.text-vert {
    overflow: unset;
    top: 20vh;
    transform: none;
  }

  .section-title.text-vert .text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .section-title.title-right-y {
    transform-origin: 100% 0;
    overflow: unset;
    width: auto;
    height: auto;
    left: auto;
    right: calc(50vw - 33rem);
    transform: rotate(90deg)translateX(100%)translateX(-30vh);
  }

  @media screen and (width >= 992px) {
    .section-title.title-right-y .text {
      font-size: 14rem;
      font-weight: 800;
      line-height: 1;
    }
  }

  .section .sticky-title {
    height: fit-content;
    position: sticky;
    top: 200px;
    transform: translateY(-50%);
  }

  .section-bd-top {
    position: relative;
  }

  .section-bd-top:before {
    content: "";
    top: 1px;
    left: var(--margin-x);
    right: var(--margin-x);
    transform-origin: 0;
    background-color: currentColor;
    height: 2px;
    transition: all .6s;
    position: absolute;
    transform: scaleX(.001);
  }

  @media screen and (width >= 992px) {
    .section-bd-top:before {
      max-width: unset;
      left: var(--margin-x);
      right: var(--margin-x);
    }
  }

  .section-bd-top.active:before {
    transform: scaleX(1);
  }

  .section-border-top {
    content: "";
    top: 1px;
    left: var(--margin-x);
    right: var(--margin-x);
    transform-origin: 0;
    background-color: currentColor;
    height: 2px;
    transition: all .6s;
    position: absolute;
  }

  @media screen and (width >= 992px) {
    .section-border-top {
      max-width: unset;
      left: var(--margin-x);
      right: var(--margin-x);
    }
  }

  .section-body {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
  }

  @media screen and (width >= 992px) {
    .section-body {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  .section-pt-0 .section-body {
    padding-top: 0;
  }

  @media screen and (width >= 992px) {
    .section-pt-0 .section-body {
      padding-top: 0;
    }
  }

  .section-pb-0 .section-body {
    padding-bottom: 0;
  }

  @media screen and (width >= 992px) {
    .section-pb-0 .section-body {
      padding-bottom: 0;
    }
  }

  .section-content.content-width-md {
    max-width: 48rem;
  }

  .section-content.content-width-lg, .section-content.content-width {
    max-width: 66rem;
  }

  @media screen and (width >= 1200px) {
    .section-content.content-width-xl, .section-content.content-xl {
      max-width: 80rem;
    }
  }

  @media screen and (width >= 992px) {
    .section-content.content-width-xxl, .section-content.content-xxl {
      max-width: 80rem;
    }
  }

  @media screen and (width >= 1200px) {
    .section-content.content-width-xxl, .section-content.content-xxl {
      padding-left: 64px;
      padding-left: var(--margin-x);
      padding-right: 64px;
      padding-right: var(--margin-x);
      max-width: 85rem;
    }
  }

  .section-content.content-width-full, .section-content.content-full {
    max-width: unset;
  }

  @media screen and (width >= 992px) {
    .section-content.content-width-full, .section-content.content-full {
      max-width: unset;
      padding-left: 64px;
      padding-left: var(--margin-x);
      padding-right: 64px;
      padding-right: var(--margin-x);
    }
  }

  .section-contact .contact-main, .section-contact .email-main {
    color: currentColor;
    font-size: 1.5rem;
    text-decoration: none;
    transition: all .2s cubic-bezier(.25, .75, .2, 1);
  }

  .section-contact .contact-main:hover, .section-contact .email-main:hover {
    opacity: 1;
  }

  @media screen and (width >= 992px) {
    .section-contact .contact-main, .section-contact .email-main {
      font-size: 2.5rem;
    }
  }

  .section-contact .dep-address {
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .section-contact .dep-address {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .section-contact .dep-address {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .section-footer-left {
    z-index: 2;
    top: auto;
    right: auto;
    left: var(--margin-x);
    bottom: var(--margin-y);
    padding-right: 1px;
    display: block;
    position: absolute;
  }

  .section-footer-center {
    z-index: 2;
    top: auto;
    right: auto;
    bottom: var(--margin-y);
    padding-right: 1px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .section-footer-right {
    top: auto;
    left: auto;
    right: var(--margin-x);
    z-index: 2;
    bottom: var(--margin-y);
    padding-right: 1px;
    display: block;
    position: absolute;
  }

  @media (width >= 768px) {
    .section-footer-md-left {
      z-index: 2;
      top: auto;
      right: auto;
      left: var(--margin-x);
      bottom: var(--margin-y);
      padding-right: 1px;
      display: block;
      position: absolute;
      transform: none;
    }

    .section-footer-md-center {
      z-index: 2;
      top: auto;
      left: 50%;
      right: auto;
      bottom: var(--margin-y);
      padding-right: 1px;
      position: absolute;
      transform: translateX(-50%);
    }

    .section-footer-md-right {
      top: auto;
      left: auto;
      right: var(--margin-x);
      z-index: 2;
      bottom: var(--margin-y);
      padding-right: 1px;
      display: block;
      position: absolute;
    }

    .section-footer-lg-left {
      z-index: 2;
      top: auto;
      right: auto;
      left: var(--margin-x);
      bottom: var(--margin-y);
      padding-right: 1px;
      display: block;
      position: absolute;
      transform: none;
    }
  }

  @media (width >= 992px) {
    .section-footer-lg-center {
      z-index: 2;
      top: auto;
      left: 50%;
      right: auto;
      bottom: var(--margin-y);
      padding-right: 1px;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  @media (width >= 768px) {
    .section-footer-lg-right {
      top: auto;
      left: auto;
      right: var(--margin-x);
      z-index: 2;
      bottom: var(--margin-y);
      padding-right: 1px;
      display: block;
      position: absolute;
    }
  }

  .section-a.child-sticky {
    overflow: initial;
  }

  .section-a .title-desc p {
    font-size: 1rem;
  }

  @media screen and (width >= 768px) {
    .section-a .title-desc p {
      font-size: 1.125rem;
    }
  }

  @media screen and (width >= 992px) {
    .section-a .title-desc p {
      font-size: 1.25rem;
    }
  }

  .section-a .title-desc.large-text-size .text-p, .section-a .title-desc.large-text-size p {
    font-size: 1.5rem;
    font-weight: 700;
  }

  @media screen and (width >= 768px) {
    .section-a .title-desc.large-text-size .text-p, .section-a .title-desc.large-text-size p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  @media screen and (width >= 992px) {
    .section-a .title-desc.large-text-size .text-p, .section-a .title-desc.large-text-size p {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .section-a .title-desc.xl-text-size .text-p, .section-a .title-desc.xl-text-size p {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .section-a .title-desc.xl-text-size .text-p, .section-a .title-desc.xl-text-size p {
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }

  @media screen and (width >= 992px) {
    .section-a .title-desc.xl-text-size .text-p, .section-a .title-desc.xl-text-size p {
      font-size: 3rem;
      line-height: 1.4;
    }
  }

  .section-a .title-desc.xl-text-size .text-p strong, .section-a .title-desc.xl-text-size .text-p .strong, .section-a .title-desc.xl-text-size p strong, .section-a .title-desc.xl-text-size p .strong {
    font-weight: 600;
  }

  .section-a.section-pt-0 .section-body {
    padding-top: 0;
  }

  @media screen and (width >= 992px) {
    .section-a.section-pt-0 .section-body {
      padding-top: 0;
    }
  }

  .section-a.section-pb-0 .section-body {
    padding-bottom: 0;
  }

  @media screen and (width >= 992px) {
    .section-a.section-pb-0 .section-body {
      padding-bottom: 0;
    }
  }

  .section-b .title-desc p {
    font-size: 1rem;
    line-height: 1.7;
  }

  @media screen and (width >= 768px) {
    .section-b .title-desc p {
      font-size: 1.125rem;
      line-height: 1.7;
    }
  }

  @media screen and (width >= 992px) {
    .section-b .title-desc p {
      font-size: 1.25rem;
      line-height: 1.7;
    }
  }

  .hero-a {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: flex-end;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-a {
      align-items: flex-end;
    }
  }

  .hero-a .section-body {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }

  @media screen and (height >= 660px) {
    .hero-a .section-body {
      padding-top: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-a .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-a .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-a .hero-content {
      padding-top: 24rem;
      padding-bottom: 16rem;
    }
  }

  .hero-a .hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.1;
  }

  @media screen and (width >= 768px) {
    .hero-a .hero-title {
      margin-bottom: 1rem;
      font-size: 6.5rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-a .hero-title {
      font-size: 6.5rem;
    }
  }

  .hero-a .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-a .hero-btns {
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-a .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
      position: absolute;
    }
  }

  .hero-a .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: absolute;
    top: 100vh;
    left: 0;
    right: 0;
  }

  .hero-b {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-b {
      align-items: center;
    }
  }

  .hero-b .section-body {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  @media screen and (height >= 660px) {
    .hero-b .section-body {
      padding-top: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-b .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-b .content-col, .hero-b .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-b .hero-content {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }

  .hero-b .hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.1;
  }

  @media screen and (width >= 768px) {
    .hero-b .hero-title {
      margin-bottom: 1rem;
      font-size: 7rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-b .hero-title {
      font-size: 8rem;
    }
  }

  .hero-b .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-b .hero-btns {
    width: 100%;
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-b .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
      position: absolute;
    }
  }

  .hero-b .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body.scrolled .hero-b .hero-footer {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
  }

  .hero-c {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-c {
      align-items: center;
    }
  }

  .hero-c .section-body {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  @media screen and (height >= 660px) {
    .hero-c .section-body {
      padding-top: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-c .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-c .content-col, .hero-c .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-c .hero-content {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }

  .hero-c .hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1;
  }

  @media screen and (width >= 768px) {
    .hero-c .hero-title {
      margin-bottom: 1rem;
      font-size: 7rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-c .hero-title {
      font-size: 8rem;
    }
  }

  .hero-c .content-part {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  @media screen and (width >= 992px) {
    .hero-c .content-part {
      margin-top: .75rem;
      margin-bottom: 2rem;
    }
  }

  .hero-c .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-c .hero-btns {
    width: 100%;
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-c .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
    }
  }

  .hero-c .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body.scrolled .hero-c .hero-footer {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
  }

  .hero-d {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-d {
      align-items: center;
    }
  }

  .hero-d .section-body {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @media screen and (height >= 660px) {
    .hero-d .section-body {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-d .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-d .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-d .hero-content {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }

  .hero-d .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-d .hero-btns {
    width: 100%;
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-d .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
      position: absolute;
    }
  }

  .hero-d .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body.scrolled .hero-d .hero-footer {
    opacity: 0;
    visibility: hidden;
    margin-top: -100px;
    transform: translateY(-20px);
  }

  .hero-item-a {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-item-a {
      align-items: center;
    }
  }

  .hero-item-a .section-body {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  @media screen and (height >= 660px) {
    .hero-item-a .section-body {
      padding-top: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-item-a .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-item-a .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-item-a .hero-content {
      padding-top: 12rem;
      padding-bottom: 4rem;
    }
  }

  .hero-item-a .hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.1;
  }

  @media screen and (width >= 768px) {
    .hero-item-a .hero-title {
      margin-bottom: 1rem;
      font-size: 6.5rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-item-a .hero-title {
      font-size: 6.5rem;
    }
  }

  .hero-item-a .subtitle {
    color: var(--white-transp-1);
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.1;
  }

  .hero-item-a .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-item-a .hero-btns {
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-item-a .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
      position: absolute;
    }
  }

  .hero-item-a .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body.scrolled .hero-item-a .hero-footer {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
  }

  .hero-item-b {
    --btns-margin-top: 2.5rem;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }

  @media screen and (width >= 768px) {
    .hero-item-b {
      align-items: flex-end;
      min-height: 100vh;
    }
  }

  .hero-item-b .section-body {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  @media screen and (height >= 660px) {
    .hero-item-b .section-body {
      padding-top: 8rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-item-b .section-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hero-item-b .hero-content {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .hero-item-b .hero-content {
      padding-left: 64px;
      padding-left: var(--margin-x);
      padding-bottom: 40px;
      padding-bottom: var(--margin-y);
    }
  }

  .hero-item-b .hero-title {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.1;
  }

  @media screen and (width >= 768px) {
    .hero-item-b .hero-title {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  @media screen and (width >= 992px) {
    .hero-item-b .hero-title {
      font-size: 5rem;
    }
  }

  .hero-item-b .subtitle {
    color: var(--white-transp-1);
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.1;
  }

  .hero-item-b .hero-desc {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .hero-item-b .hero-btns {
    margin-top: 1.5rem;
  }

  @media screen and (width >= 992px) {
    .hero-item-b .hero-btns {
      margin-top: 2rem;
      margin-top: var(--btns-margin-top);
      position: absolute;
    }
  }

  .hero-item-b .hero-footer {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body.scrolled .hero-item-b .hero-footer {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
  }

  .page-twocols {
    position: relative;
  }

  @media screen and (width >= 992px) {
    .page-twocols .col-fixed {
      align-self: flex-start;
      height: 100vh;
      position: sticky;
      top: 0;
    }

    .page-twocols .col-scroll {
      position: relative;
      top: 0;
    }

    .page-twocols .col-scroll .section-content.content-width {
      padding-left: 64px;
      padding-left: var(--margin-x);
      padding-right: 64px;
      padding-right: var(--margin-x);
      max-width: 100%;
    }
  }

  .fp-scrollable {
    width: 100%;
  }

  .cover-page {
    z-index: -1;
    height: 100vh;
    transition: all .3s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .cover-page .cover-bg {
    position: absolute;
    inset: 0;
  }

  .cover-page img, .cover-page iframe, .cover-page video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .note {
    color: #ffffff80;
    margin-bottom: 0;
  }

  .note .marked {
    font-weight: 500;
  }

  .note a {
    color: #fff;
    text-decoration: none;
  }
}
/*# sourceMappingURL=index.b23e99e0.css.map */
